<template>
  <div class="">
    <user-integration-record-output-filters
      @filters-changed="filtersUpdated">
    </user-integration-record-output-filters>


    <div v-if="isLoading">
      <loader></loader>
    </div>
    <slot v-if="!isLoading && list.data.length > 0">
      <div class="min-w-full">
        <pagination @page="setPage" :meta="list.meta"></pagination>
      </div>

      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="overflow-hidden border-t border-gray-200">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
              <tr>
                <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Contact Name
                </th>
                <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Contact Email
                </th>
                <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Contact Country
                </th>
                <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Description
                </th>
                <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Type
                </th>
                <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Date
                </th>
                <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase text-center tracking-wider text-right">
                  Currency
                </th>
                <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase text-center tracking-wider text-right">
                  Total
                </th>
                <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase text-center tracking-wider">
                  Status
                </th>
                <th scope="col" class="relative px-6 py-3 text-right">
                  <span class="sr-only">View</span>
                </th>
              </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
              <tr
                  class="cursor-pointer"
                  v-for="item in list.data"
                  @click.prevent="() => rowClicked(item)">
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ contactName(item) }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ contactEmail(item) }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ contactCountry(item) }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ item.transaction_description }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ item.transaction_type }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ asLocalTime(item.transaction_date) }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ item.transaction_currency }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-right">
                  <div class="" :class="item.transaction_total > 0 ? 'text-green-600' : 'text-red-600'">
                    {{ asMoney(item.transaction_total, item.transaction_currency) }}
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <user-integration-record-output-status
                      :record="item">
                  </user-integration-record-output-status>
                </td>

                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <a href="#" class="text-indigo-600 hover:text-indigo-900">View</a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="min-w-full">
        <pagination @page="setPage" :meta="list.meta"></pagination>
      </div>
    </slot>

    <slot name="no-results" v-if="!isLoading && list.data.length === 0">
      <h1>No results</h1>
    </slot>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Loader from "@/components/Loader";
import {formatMoney} from 'accounting-js';
import UserIntegrationRecordOutputStatus
  from "@/components/UserIntegrationRecordOutputs/UserIntegrationRecordOutputStatus";
import Pagination from "@/components/Pagination/Pagination";
import UserIntegrationRecordOutputFilters
  from "@/components/UserIntegrationRecordOutputs/UserIntegrationRecordOutputFilters";

const moment = require("moment-timezone");

export default {
  name: "ListUserIntegrationRecordOutput",

  components: {
    UserIntegrationRecordOutputFilters,
    Pagination,
    UserIntegrationRecordOutputStatus,
    Loader
  },

  props: {
    inputFilters: {
      type: Object
    },
    record: {
      type: Object
    }
  },

  data: () => ({
    searchForm: {},
  }),

  created() {
    this.loadList()
  },

  methods: {
    filtersUpdated(filter) {
      this.searchForm = {filter};
      this.loadList();
    },

    setPage(page) {
      return this.loadList({
        page: {
          number: page
        }
      })
    },

    asLocalTime(datetime) {
      let parsedTime = null;

      if (this.record.output_timezone) {
        parsedTime = moment(datetime).tz(this.record.output_timezone)
      } else {
        parsedTime = moment(datetime)
      }

      return parsedTime
          .format('MMM Do, YYYY hh:mm:ss A')
    },

    asMoney(money, currency) {
      return formatMoney(money, {symbol: currency, format: "%v"});
    },

    loadList(params = {}) {
      let filterParams = Object.assign(
          {},
          this.inputFilters,
          this.searchForm,
          params
      );

      this.$store.dispatch(
          'user_integration_record_outputs/readList',
          filterParams
      )
    },

    rowClicked(item) {
      this.viewTransaction(item.id)
    },

    contactName(item) {
      if (!item.contact) {
        return '--'
      }
      const first_name = item.contact.data.first_name || ''
      const last_name = item.contact.data.last_name || ''
      
      return first_name + ' ' + last_name;
    },

    viewTransaction(id) {
      this.$router.push({
        name: 'user_integration_settings_view_output',
        params: {
          settings_id: this.record.id,
          output_id: id
        }
      })
    },

    contactEmail(item) {
      if (!item.contact) {
        return '--'
      }

      return item.contact.data.email;
    },

    contactCountry(item) {
      if (!item.contact) {
        return '--'
      }

      return item.contact.data.country;
    }
  },

  computed: {
    ...mapGetters({
      isLoading: 'user_integration_record_outputs/getIsLoadingList',
      list: 'user_integration_record_outputs/getList'
    })
  }
}
</script>

<style scoped>

</style>