<template>
  <div class="">
    <div class="" v-if="isLoading">
      <div class="px-4 sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8">
        <div class="py-6 my-6 bg-white rounded" v-if="isLoading">
          <loader></loader>
        </div>
      </div>
    </div>
    <div class="" v-if="!isLoading">
      <div class="">
        <!-- Header -->
        <div class="bg-white shadow">
          <div class="px-4 sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8 py-6">
            <div class="lg:flex lg:items-center lg:justify-between">
              <div class="flex-1 min-w-0">
                <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                  {{ record.title }}
                </h2>
                <div class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                  <div class="mt-2 flex items-center text-sm text-gray-500">
                    <!-- Heroicon name: briefcase -->
                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="cloud-download"
                         class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" role="img"
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 640 512">
                      <path fill="currentColor"
                            d="M543.7 200.1C539.7 142.1 491.4 96 432 96c-7.6 0-15.1.8-22.4 2.3C377.7 58.3 328.1 32 272 32c-84.6 0-155.5 59.7-172.3 139.8C39.9 196.1 0 254.4 0 320c0 88.4 71.6 160 160 160h336c79.5 0 144-64.5 144-144 0-61.8-39.2-115.8-96.3-135.9zM496 432H160c-61.9 0-112-50.1-112-112 0-56.4 41.7-103.1 96-110.9V208c0-70.7 57.3-128 128-128 53.5 0 99.3 32.8 118.4 79.4 11.2-9.6 25.7-15.4 41.6-15.4 35.3 0 64 28.7 64 64 0 11.8-3.2 22.9-8.8 32.4 2.9-.3 5.9-.4 8.8-.4 53 0 96 43 96 96s-43 96-96 96zM383.6 255.6c-4.7-4.7-12.4-4.7-17.1.1L312 311.5V172c0-6.6-5.4-12-12-12h-24c-6.6 0-12 5.4-12 12v139.5l-54.5-55.8c-4.7-4.8-12.3-4.8-17.1-.1l-16.9 16.9c-4.7 4.7-4.7 12.3 0 17l104 104c4.7 4.7 12.3 4.7 17 0l104-104c4.7-4.7 4.7-12.3 0-17l-16.9-16.9z"></path>
                    </svg>
                    Stripe
                  </div>
                  <div class="mt-2 flex items-center text-sm text-gray-500">
                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="cloud-upload"
                         class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" role="img"
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 640 512">
                      <path fill="currentColor"
                            d="M543.7 200.1C539.7 142.1 491.4 96 432 96c-7.6 0-15.1.8-22.4 2.3C377.7 58.3 328.1 32 272 32c-84.6 0-155.5 59.7-172.3 139.8C39.9 196.1 0 254.4 0 320c0 88.4 71.6 160 160 160h336c79.5 0 144-64.5 144-144 0-61.8-39.2-115.8-96.3-135.9zM496 432H160c-61.9 0-112-50.1-112-112 0-56.4 41.7-103.1 96-110.9V208c0-70.7 57.3-128 128-128 53.5 0 99.3 32.8 118.4 79.4 11.2-9.6 25.7-15.4 41.6-15.4 35.3 0 64 28.7 64 64 0 11.8-3.2 22.9-8.8 32.4 2.9-.3 5.9-.4 8.8-.4 53 0 96 43 96 96s-43 96-96 96zM296.5 150.5c-4.7-4.7-12.3-4.7-17 0l-104 104c-4.7 4.7-4.7 12.3 0 17l16.9 16.9c4.7 4.7 12.4 4.7 17.1-.1l54.5-55.8V372c0 6.6 5.4 12 12 12h24c6.6 0 12-5.4 12-12V232.5l54.5 55.8c4.7 4.8 12.3 4.8 17.1.1l16.9-16.9c4.7-4.7 4.7-12.3 0-17l-104-104z"></path>
                    </svg>

                    Xero
                  </div>
                  <div class="mt-2 flex items-center text-sm text-gray-500">
                    <!-- Heroicon name: calendar -->
                    <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                            d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                            clip-rule="evenodd"/>
                    </svg>
                    Last Updated
                  </div>
                </div>
              </div>
              <div class="mt-5 flex lg:mt-0 lg:ml-4">
                <div class="hidden sm:block">
                  <button type="button"
                          class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <!-- Heroicon name: pencil -->
                    <svg class="-ml-1 mr-2 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                         fill="currentColor" aria-hidden="true">
                      <path
                          d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"/>
                    </svg>
                    Edit
                  </button>
                </div>

                <div class="sm:ml-3">
                  <button type="button"
                          class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <!-- Heroicon name: check -->
                    <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                         fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"/>
                    </svg>
                    Enable
                  </button>
                </div>

                <!-- Dropdown -->
                <div class="ml-3 relative sm:hidden">
                  <div type="button"
                       class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                       id="mobile-menu" aria-haspopup="true">
                    More
                    <!-- Heroicon name: chevron-down -->
                    <svg class="-mr-1 ml-2 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                         fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clip-rule="evenodd"/>
                    </svg>
                  </div>
                  <div
                      class="origin-top-right absolute right-0 mt-2 -mr-1 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                      aria-labelledby="mobile-menu" role="menu">
                    <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Edit</a>
                    <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">View</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Tabbed navigation -->
        <div class="bg-gray-100">
          <div class="px-4 sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8">
            <div class="hidden sm:block">
              <nav class="flex space-x-4" aria-label="Tabs">
                <!-- Current: "bg-gray-200 text-gray-800", Default: "text-gray-600 hover:text-gray-800" -->
                <a
                    href="#"
                    class="px-3 py-2 font-medium text-sm"
                    :class="key === activeNavigation ? 'bg-gray-200 text-gray-800' : ''"
                    aria-current="page"
                    v-for="key in Object.keys(availableTabs)"
                    @click.prevent="() => setCurrentTab(key)">
                  {{ getTabProperties(key).name }}
                </a>
              </nav>
            </div>
          </div>
        </div>

        <slot></slot>

      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Loader from "@/components/Loader";

export default {
  name: "LayoutUserIntegrationSettings",

  props: {
    'activeNavigation': {
      type: String
    }
  },

  components: {
    Loader,
  },

  data: function () {
    return {
      currentTab: 'overview',

      availableTabs: {
        'overview': {
          name: 'Overview',
          navigate: (vm) => {
            vm.$router.push({
              name: 'user_integration_settings_view',
              params: {
                settings_id: vm.record.id
              }
            })
          }
        },
        'reconciliation': {
          name: 'Reconciliation',
          navigate: (vm) => {
            vm.$router.push({
              name: 'user_integration_settings_reconciliation',
              params: {
                settings_id: vm.record.id
              }
            })
          }
        },
        'reports': {
          name: 'Reports',
          navigate: (vm) => {
            vm.$router.push({
              name: 'user_integration_settings_reports',
              params: {
                settings_id: vm.record.id
              }
            })
          }
        },
        'contacts': {
          name: 'Contacts',
          navigate: (vm) => {
            vm.$router.push({
              name: 'user_integration_settings_contacts',
              params: {
                settings_id: vm.record.id
              }
            })
          }
        },
        'inputFeed': {
          name: 'Input Feed',
          navigate: (vm) => {
            vm.$router.push({
              name: 'user_integration_settings_input_feed',
              params: {
                settings_id: vm.record.id
              }
            })
          }
        },
        'outputFeed': {
          name: 'Output Feed',
          navigate: (vm) => {
            vm.$router.push({
              name: 'user_integration_settings_output_feed',
              params: {
                settings_id: vm.record.id
              }
            })
          }
        },
        'settings': {
          name: 'Settings',
          navigate: (vm) => {
            vm.$router.push({
              name: 'user_integration_settings_configuration',
              params: {
                settings_id: vm.record.id
              }
            })
          }
        }
      }
    }
  },

  created() {
    console.log(' SETTINGS ID: ' + this.$route.params.settings_id)

    if (!this.record || this.record.id !== this.$route.params.settings_id) {
      this.$store.dispatch(
          'user_integration_settings/readItem',
          this.$route.params.settings_id
      ).then(() => {
        this.$emit('loaded');
      });
    } else {
      this.$emit('loaded');
    }
  },

  mounted() {
  },

  methods: {
    getTabProperties(tabName) {
      return this.availableTabs[tabName]
    },

    setCurrentTab(tabName) {
      this.getTabProperties(tabName).navigate(this);
    }
  },

  computed: {
    ...mapGetters({
      isLoading: 'user_integration_settings/isLoading',
      record: 'user_integration_settings/getView'
    })
  }
}
</script>

<style scoped>

</style>