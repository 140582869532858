<template>
  <div class="bg-white overflow-hidden shadow rounded-lg mb-4">
    <div class="px-4 py-5 sm:p-6">
      <div class="flex items-center">
        <div class="flex-shrink-0 bg-indigo-500 rounded-md p-3">
          <slot></slot>
        </div>
        <div class="ml-5 w-0 flex-1">
          <dt class="text-sm font-medium text-gray-500 truncate">
            {{ title }}
          </dt>
          <dd class="flex items-baseline">
            <div class="" v-if="isLoading">
              <loader extra-class="h-10 w-10"></loader>
            </div>
            <div class="text-2xl font-semibold text-gray-900" v-if="!isLoading">
              <div class="" v-if="getValue">
                {{ getValue }}
              </div>
              <div class="" v-else>
                --
              </div>
            </div>
          </dd>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader";

export default {
  name: "GenericKpi",

  components: {Loader},

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'KPI',
    },
    value:  {
      default: 0
    }
  },

  mounted() {

  },

  methods: {

  },

  computed: {
    getValue() {
      return this.value
    }
  }
}
</script>

<style scoped>

</style>