<template>
  <div class="relative text-left"
       v-click-outside="() => this.dropdownOpen = false">
    <button type="button"
            class="flex items-center text-sm font-medium text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
            id="menu-1" aria-expanded="false" aria-haspopup="true"
            @click.prevent="toggleDropdown">
      <span>{{ getButtonText }}</span>
      <!-- Heroicon name: solid/chevron-down -->
      <svg class="ml-1 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
           fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"/>
      </svg>
    </button>

    <!--
      Dropdown menu, show/hide based on menu state.

      Entering: "transition ease-out duration-100"
        From: "transform opacity-0 scale-95"
        To: "transform opacity-100 scale-100"
      Leaving: "transition ease-in duration-75"
        From: "transform opacity-100 scale-100"
        To: "transform opacity-0 scale-95"
    -->
    <transition
        enter-active-class="transition ease-out duration-100"
        enter-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95">
      <div
          class="origin-top-right absolute z-30 right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu" aria-orientation="vertical" aria-labelledby="menu-1"
          v-if="dropdownOpen">
        <div class="py-1">
          <a href="#"
             class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:bg-gray-100"
             v-for="option in getOptions"
             role="menuitem"
             @click.prevent="() => selectOption(option)">
            {{ option.label }}
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  name: "FilterDropDownButton",

  props: {
    name: {
      type: String,
      required: true,
    },
    text: {
      type: String
    },
    options: {
      type: Array,
      required: true
    },
    value: {
      types: [Object, null],
      required: true
    }
  },

  directives: {
    ClickOutside
  },

  data: () => ({
    selectedItem: null,
    dropdownOpen: false
  }),

  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen
    },

    selectOption(item) {
      this.dropdownOpen = false;
      this.$emit('input', item);
    },
  },

  computed: {
    getButtonText() {
      const selectedOption = this.getSelectedOption;
      console.log('Selected option', selectedOption);

      if (this.getSelectedOption && this.getSelectedOption.value) {
        return selectedOption.label
      }

      if (this.text) {
        return this.text
      }

      return this.name
    },

    getSelectedOption() {
      let cacheOptions = this.getOptions;

      for(let i = 0; i<cacheOptions.length; i++) {
        if (cacheOptions[i].value === this.getValue) {
          return cacheOptions[i];
        }
      }

      return null;
    },

    getOptions() {
      return this.options.map((item) => {
        if (typeof item == "object") {
          return item;
        }

        if (typeof item == "string") {
          let newItem = {};

          newItem["label"] = item;
          newItem["value"] = item;

          return newItem;
        }
      });
    },

    getValue() {
      return this.value[this.name];
    }
  }
}
</script>

<style scoped>

</style>