<template>
  <div class="">
    <div class="needs-reconcile flex content-center align-middle" v-if="record.sync_status === 'Needs Reconcile'" title="Needs Reconcile">
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="eye"
           class="flex-shrink-0 mr-1.5 h-5 w-5 text-yellow-600"
           role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <path fill="currentColor"
              d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"></path>
      </svg>

      <p class="text-gray-600" v-if="!record.reconcile_available">
        Pending
      </p>
      <p class="text-green-600" v-else>
        Available
      </p>
    </div>
    <div class="ignored flex content-center align-middle" v-if="record.sync_status === 'Ignored'" title="Ignored">
      <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="print-slash"
           class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-600" role="img" xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 640 512">
        <path fill="currentColor"
              d="M451.91 267.74l34.7 27.13c.68-2.21 1.39-4.43 1.39-6.87 0-13.26-10.75-24-24-24-4.51 0-8.49 1.58-12.09 3.74zM192 48h192v48c0 8.84 7.16 16 16 16h48v64H334.57l61.4 48H496c17.64 0 32 14.36 32 32v71.23l48 37.53V256c0-44.18-35.82-80-80-80V99.88c0-12.73-5.06-24.94-14.06-33.94l-51.88-51.88c-9-9-21.21-14.06-33.94-14.06H174.48c-15.37 0-27.55 12.14-29.64 27.67L192 64.54V48zm441.99 423.02L36 3.51C29.1-2.01 19.03-.9 13.51 6l-10 12.49C-2.02 25.39-.9 35.46 6 40.98l598 467.51c6.9 5.52 16.96 4.4 22.49-2.49l10-12.49c5.52-6.9 4.41-16.97-2.5-22.49zM448 464H192v-64h195.3l-61.4-48H112v-96c0-17.64 14.36-32 32-32h18.18l-51.7-40.42C83.1 196.29 64 223.83 64 256v128c0 8.84 7.16 16 16 16h64v96c0 8.84 7.16 16 16 16h320c8.84 0 16-7.16 16-16v-11.02l-48-37.53V464z"></path>
      </svg>
      <p class="text-gray-300">
        Ignored
      </p>
    </div>
    <div class="pending flex content-center align-middle" v-if="record.sync_status === 'Pending'" title="Pending">
      <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="circle"
           class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-600 animate-pulse" role="img" xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 512 512">
        <g class="fa-group">
          <path class="fa-secondary" fill="currentColor"
                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 424c-97.06 0-176-79-176-176S158.94 80 256 80s176 79 176 176-78.94 176-176 176z"
                opacity="0.4"></path>
          <path class="fa-primary" fill="currentColor"
                d="M256 432c-97.06 0-176-79-176-176S158.94 80 256 80s176 79 176 176-78.94 176-176 176z"></path>
        </g>
      </svg>
      <p class="text-gray-600">
        Pending
      </p>
    </div>
    <div class="syncing flex content-center align-middle" v-if="record.sync_status === 'Syncing'" title="Syncing">
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sync"
           class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-600 animate-spin" role="img" xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 512 512">
        <path fill="currentColor"
              d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z"></path>
      </svg>
      <p class="text-gray-600">
        Syncing
      </p>
    </div>
    <div class="finished-complete flex content-center align-middle" v-if="record.sync_status === 'Finished - Complete'" title="Finished - Complete">
      <svg xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 20 20"
           fill="currentColor"
           aria-hidden="true"
           class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400">
        <path fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"></path>
      </svg>
      <p class="text-green-400">
        Finished - Complete
      </p>
    </div>
    <div class="finished-failed flex content-center align-middle" v-if="record.sync_status === 'Finished - Failed'" title="Finished - Failed">
      <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fad"
          data-icon="times-circle"
          class="flex-shrink-0 mr-1.5 h-5 w-5 text-red-600"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512">
        <g class="fa-group">
          <path class="fa-secondary" fill="currentColor"
                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1a12 12 0 0 1 0 17L338 377.6a12 12 0 0 1-17 0L256 312l-65.1 65.6a12 12 0 0 1-17 0L134.4 338a12 12 0 0 1 0-17l65.6-65-65.6-65.1a12 12 0 0 1 0-17l39.6-39.6a12 12 0 0 1 17 0l65 65.7 65.1-65.6a12 12 0 0 1 17 0l39.6 39.6a12 12 0 0 1 0 17L312 256z"
                opacity="0.4"></path>
          <path class="fa-primary" fill="currentColor"
                d="M377.6 321.1a12 12 0 0 1 0 17L338 377.6a12 12 0 0 1-17 0L256 312l-65.1 65.6a12 12 0 0 1-17 0L134.4 338a12 12 0 0 1 0-17l65.6-65-65.6-65.1a12 12 0 0 1 0-17l39.6-39.6a12 12 0 0 1 17 0l65 65.7 65.1-65.6a12 12 0 0 1 17 0l39.6 39.6a12 12 0 0 1 0 17L312 256z"></path>
        </g>
      </svg>
      <p class="text-red-600">
        Finished - Failed
      </p>
    </div>
    <div class="failing flex content-center align-middle" v-if="record.sync_status === 'Failing'" title="Failing">
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation-triangle"
           class="flex-shrink-0 mr-1.5 h-5 w-5 text-yellow-600 animate-pulse" role="img" xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 576 512">
        <path fill="currentColor"
              d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"></path>
      </svg>
      <p class="text-yellow-600">
        Failing
      </p>
    </div>
    <div class="duplicate flex content-center align-middle" v-if="record.sync_status === 'Duplicate'" title="Duplicate">
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="copy"
           class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-600" role="img" xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 448 512">
        <path fill="currentColor"
              d="M320 448v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V120c0-13.255 10.745-24 24-24h72v296c0 30.879 25.121 56 56 56h168zm0-344V0H152c-13.255 0-24 10.745-24 24v368c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V128H344c-13.2 0-24-10.8-24-24zm120.971-31.029L375.029 7.029A24 24 0 0 0 358.059 0H352v96h96v-6.059a24 24 0 0 0-7.029-16.97z"></path>
      </svg>
      <p class="text-gray-300">
        Duplicate
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserIntegrationRecordOutputStatus",
  props: {
    record: {
      type: Object,
      required: true
    }
  },

  computed: {
    getIsReconcileStatusPending() {
      return new Date() < new Date(this.record.sync_available_at)
    }
  }
}
</script>

<style scoped>

</style>