<template>
  <layout-user-integration-settings
      active-navigation="reports"
      @loaded="updateData">
    <div class="">

      <div class="mt-8 px-4 sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8">
        <!-- List Results -->
        <section aria-labelledby="billing_history_heading">
          <div class="sm:overflow-hidden">
            <div class="bg-white py-6 shadow sm:rounded-md px-4 sm:px-6 md:flex md:items-center md:justify-between">
              <div class="flex-1 min-w-0">
                <!-- Profile -->
                <div class="flex items-center">
                  <div>
                    <div class="flex items-center">
                      <h2 id="billing_history_heading" class="text-lg leading-6 font-medium text-gray-900">
                        System Balance Report
                      </h2>
                    </div>
                    <div class="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                      <div class="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                        <p>
                          Compare daily balances between systems
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-6 flex space-x-3 md:mt-0 md:ml-4">

              </div>
            </div>
            <div class="mt-6 flex flex-col">
              <report-daily-totals-filter
                  @filters-changed="filtersUpdated">
              </report-daily-totals-filter>

              <div class="pt-5" v-if="!isLoading">
                <div class="pb-5 border-b border-gray-200 mb-4">
                  <div class="-ml-2  flex flex-wrap items-baseline">
                    <h3 class="ml-2 mt-2 text-xl leading-6 font-medium text-gray-900">
                      Aggregates
                    </h3>
                    <p class="ml-2 mt-1 text-sm text-gray-500 truncate">Compare the sum of all balances for the selected
                      date range</p>
                  </div>
                </div>

                <div class="" v-if="!isLoadingAggregates">
                  <section aria-labelledby="billing_history_heading">
                    <div
                        class="bg-white shadow sm:rounded-md sm:overflow-hidden mt-4">
                      <div class="w-full">
                        <div class="px-4 py-4">
                          <h3 class="text-2xl">Service Aggregates</h3>
                        </div>

                        <table class="min-w-full divide-y divide-gray-200">
                          <thead class="bg-gray-50">
                          <tr>
                            <th scope="col"
                                class="flex-1 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Name
                            </th>
                            <th scope="col"
                                v-for="service_key in uniqueServices"
                                class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                              {{ service_key }}
                            </th>
                          </tr>
                          </thead>
                          <tbody class="bg-white divide-y divide-gray-200">
                          <tr v-for="transaction_type in transactionTypes">
                            <td
                                class="flex-1 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {{ transaction_type }}
                            </td>
                            <td
                                class="text-right px-6"
                                v-for="service_key in uniqueServices">
                              {{ getAggregateValue(service_key, transaction_type) }}
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </section>
                </div>
                <div class="" v-else>
                  <div class="">
                    <div class="py-6 my-6 bg-white rounded">
                      <loader></loader>
                    </div>
                  </div>
                </div>

                <div class="pb-5 border-b border-gray-200">
                  <div class="-ml-2  flex flex-wrap items-baseline">
                    <h3 class="ml-2 mt-2 text-xl leading-6 font-medium text-gray-900">
                      Daily Totals
                    </h3>
                    <p class="ml-2 mt-1 text-sm text-gray-500 truncate">Compare each service by day</p>
                  </div>
                </div>
                <div class="" v-if="!isLoadingReport">
                  <div class=""
                       v-for="date_key in Object.keys(reportData)">
                    <section aria-labelledby="billing_history_heading">
                      <div
                          class="bg-white shadow sm:rounded-md sm:overflow-hidden mt-4">
                        <div class="w-full">
                          <div class="px-4 py-4">
                            <h3 class="text-2xl">{{ date_key }}</h3>
                          </div>

                          <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                            <tr>
                              <th scope="col"
                                  class="flex-1 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Name
                              </th>
                              <th scope="col"
                                  v-for="service_key in uniqueServices"
                                  class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {{ service_key }}
                              </th>
                            </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-for="transaction_type in transactionTypes">
                              <td
                                  class="flex-1 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {{ transaction_type }}
                              </td>
                              <td
                                  class="text-right px-6"
                                  v-for="service_key in uniqueServices">
                                {{ formatAsMoney(getCashValue(date_key, transaction_type, service_key)) }}
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <div class="" v-else>
                  <div class="">
                    <div class="py-6 my-6 bg-white rounded">
                      <loader></loader>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </section>
      </div>
    </div>

  </layout-user-integration-settings>
</template>

<script>
import LayoutUserIntegrationSettings from "@/views/UserIntegrationSettings/Layout";
import {mapActions, mapGetters} from "vuex";
import Loader from "@/components/Loader";
import {formatMoney} from 'accounting-js';
import ReportDailyTotalsFilter from "@/components/ReportDailyTotals/ReportDailyTotalsFilter";
import GenericKpi from "@/components/KPIs/GenericKpi";

export default {
  name: "BalanceReport",

  components: {
    GenericKpi,
    ReportDailyTotalsFilter,
    Loader,
    LayoutUserIntegrationSettings
  },

  data: () => ({
    searchForm: {}
  }),

  mounted() {
  },

  methods: {
    getAggregateValue(service_name, transaction_type) {
      if (!this.aggregates.hasOwnProperty(service_name)) {
        console.log("No service: " + service_name);
        return '--';
      }

      if (!this.aggregates[service_name].hasOwnProperty(transaction_type)) {
        console.log("No Transaction Type: Service: " + service_name + " - " + transaction_type);
        return '--';
      }

      const value = this.aggregates[service_name][transaction_type]
      console.log("Value: " + value);

      return formatMoney(value, {symbol: 'AUD', format: "%v"});
    },

    filtersUpdated(filter) {
      this.searchForm = {filter};
      this.loadReport();
    },

    loadReport(params = {}) {
      let filterParams = Object.assign(
          {},
          this.inputFilters,
          this.searchForm,
          params
      );

      this.readReport(filterParams);
      this.readAggregates(filterParams);
    },

    formatAsMoney(money) {
      return formatMoney(money)
    },

    getCashValue(date, transaction_type, service) {
      const selectedItem = this.reportData[date][transaction_type][service];

      if (selectedItem) {
        return selectedItem
      }

      return 0;
    },

    updateData() {
      this.loadReport();
    },

    ...mapActions('report_daily_totals', [
      'readList',
      "readAggregates",
      'readReport'
    ])
  },

  computed: {
    isLoading() {
      return this.isLoadingRecord
    },

    ...mapGetters({
      isLoadingAggregates: 'report_daily_totals/getIsLoadingAggregates',
      isLoadingRecord: 'user_integration_settings/isLoading',
      record: 'user_integration_settings/getView',
      isLoadingReport: 'report_daily_totals/getIsLoadingReport',
      reportData: 'report_daily_totals/getReportList',
      uniqueServices: 'report_daily_totals/getUniqueServices',
      transactionTypes: 'report_daily_totals/getUniqueTransactionTypes',
      aggregates: 'report_daily_totals/getAggregates'
    })
  }
}
</script>

<style scoped>

</style>