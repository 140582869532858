<template>
  <div class="max-w-2xl relative text-gray-400 focus-within:text-gray-500">
    <label for="search" class="sr-only">{{ text }}</label>

    <input
        id="search"
        type="search"
        :placeholder="text"
        class="block w-full border-transparent pl-14 placeholder-gray-500 focus:border-transparent sm:text-sm focus:ring-0"
        @input="(e) => updateSearchEvent( e.target.value)"
        :value="getValue">

    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center justify-center pl-4">
      <!-- Heroicon name: solid/search -->
      <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
           aria-hidden="true">
        <path fill-rule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clip-rule="evenodd"/>
      </svg>
    </div>

  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "FilterText",

  props: {
    name: {
      type: String,
      required: true,
    },
    text: {
      type: String
    },
    value: {
      types: [Object, null],
      required: true
    },
  },

  methods: {
    updateSearch(value) {
      let newObj = {};
      newObj[this.name] = value;

      this.$emit('input', newObj);
    },

    updateSearchEvent(value) {
      this.updateSearchDebounce(value, this)
    },

    updateSearchDebounce: _.debounce((value, vm) => {
      vm.updateSearch(value)
    }, 800),
  },

  computed: {
    getValue() {
      return this.value[this.name];
    }
  }
}
</script>

<style scoped>

</style>