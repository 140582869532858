import { render, staticRenderFns } from "./InputRecordList.vue?vue&type=template&id=2707f500&scoped=true"
import script from "./InputRecordList.vue?vue&type=script&lang=js"
export * from "./InputRecordList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2707f500",
  null
  
)

export default component.exports