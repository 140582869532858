<template>
  <layout-user-integration-settings
      active-navigation="outputFeed">
    <div class="mt-8 px-4 sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8">
      <!-- List Results -->
      <section aria-labelledby="billing_history_heading">
        <div class="sm:overflow-hidden">
          <div class="bg-white py-6 shadow sm:rounded-md px-4 sm:px-6 md:flex md:items-center md:justify-between">
            <div class="flex-1 min-w-0">
              <!-- Profile -->
              <div class="flex items-center">
                <div>
                  <div class="flex items-center">
                    <h2 id="out_record_feed" class="text-lg leading-6 font-medium text-gray-900">
                      Raw Output Feed
                    </h2>
                  </div>
                  <div class="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                    <div class="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                      <p>
                        Records which are scheduled for output
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-6 flex space-x-3 md:mt-0 md:ml-4">
              <!-- Buttons -->
            </div>
          </div>

          <div class="mt-6 flex flex-col">
            <list-user-integration-record-output
                :filter="getInputFilters"
                :record="record">
              <template slot="no-results">
                <div
                    class="p-8 bg-white rounded shadow-md flex flex-col align-middle justify-center items-center">
                  <h2 class="text-3xl mb-4">This integration has no output records</h2>
                </div>
              </template>
            </list-user-integration-record-output>
          </div>
        </div>
      </section>
    </div>
  </layout-user-integration-settings>
</template>

<script>
import {mapGetters} from "vuex";
import LayoutUserIntegrationSettings from "@/views/UserIntegrationSettings/Layout";
import ListUserIntegrationRecordOutput from "@/components/UserIntegrationRecordOutputs/ListUserIntegrationRecordOutput";

export default {
  name: "ViewAccountOutputFeed",

  components: {
    ListUserIntegrationRecordOutput,
    LayoutUserIntegrationSettings
  },

  data: () => {
    return {
      showCreateModal: false,
    }
  },

  methods: {
    createdItem(created_object) {
      this.$router.push({
        name: 'user_integration_settings_view',
        params: {
          'id': created_object.data.id
        }
      })
    },

    toggleModal() {
      this.showCreateModal = !this.showCreateModal
    },
  },

  computed: {
    getInputFilters() {
      return {
        filter: {
          user_integration_settings_id: this.record.id
        }
      }
    },

    ...mapGetters({
      isLoading: 'user_integration_settings/isLoading',
      record: 'user_integration_settings/getView'
    })
  }
}
</script>

<style scoped>

</style>