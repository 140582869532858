<template>
  <div class="bg-white border-gray-200 lg:min-w-0 lg:flex-1 lg:flex lg:items-center lg:justify-between p-2">
    <div class="min-w-0 flex-1 py-5">
    </div>
    <div class="ml-10 pr-4 flex-shrink-0 flex items-center space-x-10">
      <filter-date-range
          name="reporting_date"
          text="Transaction Date"
          :value="searchForm"
          @input="updateDateSearchField">
      </filter-date-range>


      <nav aria-label="Global" class="flex space-x-10">
        <a href="#" class="text-sm font-medium text-gray-900" @click.prevent="clearFilters">
          Clear
        </a>
      </nav>
    </div>
  </div>
</template>

<script>
import FilterDateRange from "@/components/Filters/FilterDateRange";
export default {
  name: "ReportDailyTotalsFilter",

  components: {
    FilterDateRange,
  },
  
  data: () => ({
    searchForm: {},
  }),

  methods: {
    updateSearch(value) {
      this.searchForm = Object.assign({}, this.searchForm, value)
      this.emitFilterUpdate();
    },


    emitFilterUpdate() {
      const form = this.searchForm;

      const cleanedFilters = Object.keys(form)
          .reduce((carry, item) => {
            if (this.searchForm[item]) {
              carry[item] = form[item];
            }

            return carry
          }, {});


      this.$emit('filters-changed', cleanedFilters);
    },

    updateDateSearchField(value) {
      this.updateSearch(value)
    },

    clearFilters() {
      this.searchForm = {};
      this.emitFilterUpdate();
    }
  }
}
</script>

<style scoped>

</style>