<template>
  <layout-user-integration-settings
      active-navigation="contacts">
    <div class="mt-8 px-4 sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8">
      <!-- List Results -->
      <section aria-labelledby="billing_history_heading">
        <div class="bg-white py-6 shadow sm:rounded-md sm:overflow-hidden">
          <div class="px-4 sm:px-6 md:flex md:items-center md:justify-between">
            <div class="flex-1 min-w-0">
              <!-- Profile -->
              <div class="flex items-center">
                <div>
                  <div class="flex items-center">
                    <h2 id="billing_history_heading" class="text-lg leading-6 font-medium text-gray-900">
                      Contacts
                    </h2>
                  </div>
                  <div class="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                    <div class="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                      <p>
                        Contacts are customers or accounts which are related to a transaction.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-6 flex space-x-3 md:mt-0 md:ml-4">
              <button type="button"
                      class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                      @click.self="toggleModal">
                Add New Contact
              </button>
            </div>
          </div>
        </div>
      </section>

      <list-user-integration-settings-contacts
          :input-filters="getContactFilters">
        <template slot="no-results">
          <div
              class="p-8 bg-white rounded shadow-md flex flex-col align-middle justify-center items-center">
            <h2 class="text-3xl mb-4">This integration has no contacts</h2>

            <div class="mb-5">
              <button type="submit"
                      class="flex justify-center items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                      @click.self="toggleModal">

                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus"
                     class="mr-2 h-4 w-4 text-white" role="img" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 448 512">
                  <path fill="currentColor"
                        d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
                </svg>

                Get Started
              </button>
            </div>
          </div>
        </template>
      </list-user-integration-settings-contacts>


      <!--Modal-->
      <create-user-integration-settings-contact
          :show-modal="showCreateModal"
          :initial-data="{user_integration_settings_id: record.id}"
          @created="createdItem"
          @closed="toggleModal">
      </create-user-integration-settings-contact>
    </div>
  </layout-user-integration-settings>
</template>

<script>
import {mapGetters} from "vuex";
import LayoutUserIntegrationSettings from "@/views/UserIntegrationSettings/Layout";
import CreateUserIntegrationSettingsContact
  from "@/components/UserIntegrationSettingsContact/CreateUserIntegrationSettingsContact";
import ListUserIntegrationSettingsContacts
  from "@/components/UserIntegrationSettingsContact/ListUserIntegrationSettingsContacts";

export default {
  name: "ViewAccountContacts",

  components: {
    CreateUserIntegrationSettingsContact,
    ListUserIntegrationSettingsContacts,
    LayoutUserIntegrationSettings
  },

  data: () => {
    return {
      showCreateModal: false,
    }
  },

  methods: {
    createdItem(created_object) {
      this.$router.push({
        name: 'user_integration_settings_view',
        params: {
          'id': created_object.data.id
        }
      })
    },

    toggleModal() {
      this.showCreateModal = !this.showCreateModal
    },
  },

  computed: {
    getContactFilters() {
      return {
        filter: {
          user_integration_settings_id: this.record.id
        }
      }
    },

    ...mapGetters({
      isLoading: 'user_integration_settings/isLoading',
      record: 'user_integration_settings/getView'
    })
  }
}
</script>

<style scoped>

</style>