<template>
  <div class="">
    <div class="bg-white shadow">
      <div class="px-4 sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8">
        <div class="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
          <div class="flex-1 min-w-0">
            <!-- Profile -->
            <div class="flex items-center">
              <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="comments-alt-dollar" class="hidden h-16 w-16  sm:block" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M224 234.6c21.85-1.29 39.38-19.62 39.38-42.46 0-18.98-12.34-35.94-30-41.23l-41.56-12.47c-4.22-1.27-7.19-5.62-7.19-10.58 0-6.03 4.34-10.94 9.66-10.94h25.94c3.9 0 7.65 1.08 10.96 3.1 3.17 1.93 7.31 1.15 10-1.4l11.44-10.87c3.53-3.36 3.38-9.22-.54-12.11-8.18-6.03-17.97-9.58-28.08-10.32V72c0-4.42-3.58-8-8-8h-16c-4.42 0-8 3.58-8 8v13.4c-21.85 1.29-39.38 19.62-39.38 42.46 0 18.98 12.34 35.94 30 41.23l41.56 12.47c4.22 1.27 7.19 5.62 7.19 10.58 0 6.03-4.34 10.94-9.66 10.94h-25.94c-3.9 0-7.65-1.08-10.96-3.1-3.17-1.94-7.31-1.15-10 1.4l-11.44 10.87c-3.53 3.36-3.38 9.22.54 12.11 8.18 6.03 17.97 9.58 28.08 10.32V248c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8v-13.4zM512 160h-96V64c0-35.35-28.65-64-64-64H64C28.65 0 0 28.65 0 64v192c0 35.35 28.65 64 64 64h32v51.98c0 7.1 5.83 12.02 12.05 12.02 2.41 0 4.87-.74 7.08-2.37L192 338.12V384c0 35.35 28.65 64 64 64h96l108.87 61.63c2.21 1.63 4.68 2.37 7.08 2.37 6.22 0 12.05-4.92 12.05-12.02V448h32c35.35 0 64-28.65 64-64V224c0-35.35-28.65-64-64-64zM128 337.57V288H64c-17.64 0-32-14.36-32-32V64c0-17.64 14.36-32 32-32h288c17.64 0 32 14.36 32 32v192c0 17.64-14.36 32-32 32H215.57L128 337.57zM544 384c0 17.64-14.36 32-32 32h-64v49.57L360.43 416H256c-17.64 0-32-14.36-32-32v-64h128c35.35 0 64-28.65 64-64v-64h96c17.64 0 32 14.36 32 32v160z"></path></svg>
              <div>
                <div class="flex items-center">
                  <img class="h-16 w-16 rounded-full sm:hidden"
                       src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.6&w=256&h=256&q=80"
                       alt="">
                  <h1 class="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                    User Integrations
                  </h1>
                </div>
                <div class="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                  <div class="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                    <p>User Integrations represent a connection from a data source to an output</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-6 flex space-x-3 md:mt-0 md:ml-4">
            <button type="button"
                    class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                    @click.self="toggleModal">
              Add New Integration
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-8 px-4 sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8">


      <!-- List Results -->
      <div class="text-gray-900">
        <div class="mt-3 mb-3 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <h3 class="text-xl leading-6 font-medium text-gray-900">
            Integrations
          </h3>
          <div class="mt-3 flex sm:mt-0 sm:ml-4">
          </div>
        </div>
        <list-user-integration-settings>
          <template slot="no-results">
            <div class="p-8 bg-white rounded shadow-md flex flex-col align-middle justify-center items-center">
              <h2 class="text-3xl mb-4">You have no Managed Accounts</h2>

              <div class="mb-5">
                <button type="submit"
                        class="flex justify-center items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                        @click.self="toggleModal">

                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus"
                       class="mr-2 h-4 w-4 text-white" role="img" xmlns="http://www.w3.org/2000/svg"
                       viewBox="0 0 448 512">
                    <path fill="currentColor"
                          d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
                  </svg>

                  Get Started
                </button>
              </div>
            </div>
          </template>
        </list-user-integration-settings>
      </div>


      <!--Modal-->
      <create-user-integration-settings
          :show-modal="showCreateModal"
          @created="createdItem"
          @closed="toggleModal">
      </create-user-integration-settings>
    </div>
  </div>
</template>

<script>
import CreateUserIntegrationSettings from "@/components/UserIntegrationSettings/CreateUserIntegrationSettings";
import ListUserIntegrationSettings from "@/components/UserIntegrationSettings/ListUserIntegrationSettings";

export default {
  name: "Settings",

  components: {
    ListUserIntegrationSettings,
    CreateUserIntegrationSettings,
  },

  data: () => {
    return {
      showCreateModal: false,
    }
  },

  mounted() {
    this.$store.dispatch('authentication/checkLogin');
  },

  methods: {
    createdItem(created_object) {
      this.$router.push({
        name: 'user_integration_settings_view',
        params: {
          'id': created_object.data.id
        }
      })
    },

    toggleModal() {
      this.showCreateModal = !this.showCreateModal
    },
  },

  computed: {}
}
</script>

<style scoped>
</style>