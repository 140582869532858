<template>
  <div class="inline-flex items-center">
    <button
        type="button"
        class="px-3 ml-2 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        @click.prevent="openModal">
      Rollback & Rerun Transactions
    </button>


    <modal
        :show-modal="showModal"
        title="Import Transactions"
        @closed="modalClosed">
      <!--Footer-->
      <template #default="slotProps">
        <div class="">
          <h2 class="text-xl">
            Rollback & Rerun Transactions
          </h2>
          <p>
            This tool will roll back Tea Leaf transactions and fees, and re-process them. If the transaction has already been reconciled,
            it will be unaffected.
          </p>

          <div class="py-6" v-if="isLoading">
            <loader></loader>
          </div>

          <div class="" v-if="!isLoading">
            <form class="pt-6 pb-8 mb-4 bg-white rounded" @submit.prevent="importTransactions">


              <div class="mb-4">
                <label class="block mb-2 text-sm font-bold text-gray-700"
                       for="from_date"
                       :class="{'text-red-500': createErrors && createErrors.from_date}">
                  From
                </label>

                <div class="flex">
                  <input
                      class="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                      :class="{'mb-3 border-red-500': createErrors && createErrors.from_date }"
                      id="from_date"
                      type="date"
                      placeholder=""
                      v-model="form.from_date"
                      ref="from_date"
                  />
                </div>

                <p class="text-xs italic text-red-500"
                   v-if="createErrors && createErrors.from_date">
                  {{ createErrors.from_date[0] }}
                </p>
              </div>

              <div class="mb-4">
                <label class="block mb-2 text-sm font-bold text-gray-700"
                       for="to_date"
                       :class="{'text-red-500': createErrors && createErrors.to_date}">
                  To
                </label>

                <div class="flex">
                  <input
                      class="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                      :class="{'mb-3 border-red-500': createErrors && createErrors.to_date }"
                      id="to_date"
                      type="date"
                      placeholder=""
                      v-model="form.to_date"
                      ref="to_date"
                  />
                </div>

                <p class="text-xs italic text-red-500"
                   v-if="createErrors && createErrors.to_date">
                  {{ createErrors.to_date[0] }}
                </p>
              </div>


            </form>


            <div class="flex justify-end pt-2">
              <button
                  class="px-4 bg-transparent p-3 rounded-lg text-indigo-500 hover:bg-gray-100 hover:text-indigo-400 mr-2"
                  @click.prevent="modalClosed">
                Cancel
              </button>
              <button class="modal-close px-4 bg-indigo-500 p-3 rounded-lg text-white hover:bg-indigo-400"
                      @click.prevent="reRunTransactions">
                Rollback Transactions
              </button>
            </div>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Modal from "@/components/Modal";
import moment from 'moment';
import Loader from "@/components/Loader";

export default {
  name: "RollbackTransactions",
  components: {Loader, Modal},
  props: {
    record: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    showModal: false,

    form: {
      from_date: null,
      to_date: null,
    }
  }),

  methods: {
    openModal() {
      this.showModal = true;

      this.form.from_date = moment().subtract(1, "weeks").format('YYYY-MM-DD');
      this.form.to_date = moment().format('YYYY-MM-DD');
    },

    modalClosed() {
      this.showModal = false;
    },

    reRunTransactions() {
      this.$store.dispatch(
          'user_integration_settings/reRunTransactions',
          {
            id: this.record.id,
            data: this.form
          }
      ).then((created_object) => {
        this.modalClosed();
      })
    }
  },

  computed: {
    ...mapGetters({
      isLoading: 'user_integration_settings/getIsReRunningTransactions'
    }),

    createErrors() {
      return this.$store.state.user_integration_settings.isReRunningTransactionsErrors;
    }
  }
}
</script>

<style scoped>

</style>