<template>

  <div class="bg-white border-gray-200 lg:min-w-0 lg:flex-1 lg:flex lg:items-center lg:justify-between p-2">
    <div class="min-w-0 flex-1">
      <filter-text
        name="search"
        text="Filter transaction descriptions"
        @input="updateDescriptionSearch"
        :value="searchForm">
      </filter-text>
    </div>
    <div class="ml-10 pr-4 flex-shrink-0 flex items-center space-x-10">
      <drop-down-button
          name="transaction_type"
          text="Transaction Type"
          :options="getTransactionTypeFilters"
          :value="searchForm"
          @input="updateTransactionType">
      </drop-down-button>

      <date-range
        name="transaction_date"
        text="Transaction Date"
        :value="searchForm"
        @input="updateTransactionDateFilter">
      </date-range>

      <filter-money
          name="transaction_total"
          text="Transaction Amount"
          :value="searchForm"
          @input="updateTransactionAmountFilter">
      </filter-money>

      <nav aria-label="Global" class="flex space-x-10">
        <a href="#" class="text-sm font-medium text-gray-900" @click.prevent="clearFilters">
          Clear
        </a>
      </nav>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import DropDownButton from "@/components/Filters/FilterDropDownButton";
import DateRange from "@/components/Filters/FilterDateRange";
import FilterText from "@/components/Filters/FilterText";
import FilterMoney from "@/components/Filters/FilterMoney";

export default {
  name: "user-integration-record-input-filters",

  components: {
    FilterMoney,
    FilterText,
    DateRange,
    DropDownButton
  },

  data: () => ({
    selectedTransactionType: null,
    searchForm: {}
  }),

  methods: {
    updateSearch(value) {
      this.searchForm = Object.assign({}, this.searchForm, value)
      this.emitFilterUpdate();
    },

    updateDescriptionSearch(obj) {
      this.updateSearch(obj)
    },

    updateTransactionDateFilter(obj) {
      this.updateSearch(obj)
    },

    updateTransactionAmountFilter(obj) {
      this.updateSearch(obj)
    },

    updateTransactionType(obj) {
      this.selectedTransactionType = obj;

      this.updateSearch({
        'transaction_type': obj.value
      });
    },


    emitFilterUpdate() {
      const form = this.searchForm;

      const cleanedFilters = Object.keys(form)
          .reduce((carry, item) => {
            if (this.searchForm[item]) {
              carry[item] = form[item];
            }

            return carry
          }, {});


      this.$emit('filters-changed', cleanedFilters);
    },

    clearFilters() {
      this.searchForm = {};
      this.selectedTransactionType = null;
      this.emitFilterUpdate();
    }
  },

  computed: {
    getTransactionTypeFilters() {
      return [
        {label: 'Any', value: null},
        'Adjustment',
        'Charge',
        'Fee',
        'Refund',
        'Transfer'
      ]
    }
  }
}
</script>

<style scoped>

</style>