<template>
  <div class="">
    <div class="">
      <div class=" grid grid-cols-7">
        <div class="col-span-3 border-t border-gray-200 bg-white px-4  py-1 shadow">
          <div class="grid grid-cols-8">
            <div class="col-span-4  border-r border-gray-200">

              <dl class="">

                <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ item.transaction_description }}
                  </dd>
                </div>

                <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ transactionDate }}
                  </dd>
                </div>

                <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ item.transaction_type }}
                  </dd>
                </div>


                <!-- Contact fields -->
                <div class="py-1 border-t border-gray-200 sm:grid sm:grid-cols-3 sm:gap-4" v-if="item.contact">
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ contactEmail }}
                  </dd>
                </div>
                <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-4" v-if="item.contact">
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ contactName }}
                  </dd>
                </div>
                <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-4" v-if="item.contact">
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ contactCountry }}
                  </dd>
                </div>

              </dl>
            </div>
            <div class="col-span-2  border-r border-gray-200">
              <div class="py-3 px-2 text-xl text-right" v-if="item.transaction_total < 0">
                {{ transactionTotal }}
              </div>
            </div>
            <div class="col-span-2">
              <div class="py-3 px-2 text-xl text-right" v-if="item.transaction_total > 0">
                {{ transactionTotal }}
              </div>
            </div>
          </div>

        </div>
        <div class="flex flex-wrap content-center justify-center">
          <div class="" v-if="isLoading">
            <loader></loader>
          </div>
          <button
              v-else
              type="button"
              class="px-4 py-1 border border-transparent shadow-sm text-sm font-medium text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none "
              @click.prevent="reconcileItem">
            OK
          </button>
        </div>
        <div class="col-span-3 border-t border-gray-200 bg-white rounded shadow">

          <div class="bg-blue-200">
            <nav class="flex space-x-4" aria-label="Tabs">
              <!-- Current: "bg-gray-200 text-gray-800", Default: "text-gray-600 hover:text-gray-800" -->
              <a
                  href="#"
                  class="px-3 py-2 font-medium text-sm"
                  :class="reconcile_type == 'transaction' ? 'bg-blue-300 text-gray-800' : ''"
                  aria-current="page"
                  @click.prevent="() => reconcile_type = 'transaction'">
                Transaction
              </a>

              <a
                  href="#"
                  class="px-3 py-2 font-medium text-sm"
                  :class="reconcile_type == 'transfer' ? 'bg-blue-300 text-gray-800' : ''"
                  aria-current="page"
                  @click.prevent="() => reconcile_type = 'transfer'">
                Transfer
              </a>
            </nav>
          </div>

          <dl class="sm:divide-y sm:divide-gray-200 px-4 py-1 " v-if="reconcile_type == 'transfer'">

            <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">
                Bank Account
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <v-select
                    label="name"
                    :filterable="false"
                    :options="availableBankAccounts"
                    @search="actionSearchCategories"
                    v-model="selectedBankAccount">
                </v-select>
              </dd>
            </div>
          </dl>
          <dl class="sm:divide-y sm:divide-gray-200 px-4 py-1 " v-if="reconcile_type == 'transaction'">

            <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">
                Why
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input
                    class="w-full px-3 py-1 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                    name="description"
                    type="text"
                    placeholder=""
                    required
                    autocomplete="off"
                    v-model="reconcile_form.description"
                />
              </dd>
            </div>

            <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-4">

              <dt class="text-sm font-medium text-gray-500">
                Who
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <v-select
                    label="email"
                    :filterable="false"
                    :options="availableContacts"
                    @search="actionSearchContacts"
                    v-model="selectedContact">
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      <img :src='option.photo_url'/>
                      {{ option.first_name }} {{ option.last_name }} - {{ option.email }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      <img :src='option.photo_url'/>
                      {{ option.first_name }} {{ option.last_name }} - {{ option.email }}
                    </div>
                  </template>
                </v-select>
              </dd>
            </div>
            <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">
                What
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">

                <v-select
                    label="name"
                    :filterable="false"
                    :options="availableChartAccounts"
                    @search="actionSearchChartAccounts"
                    v-model="selectedChartAccount">

                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.account_code }} - {{ option.name }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.account_code }} - {{ option.name }}
                    </div>
                  </template>
                </v-select>

              </dd>
            </div>
            <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">
                Category 1
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <v-select
                    label="name"
                    :filterable="false"
                    :options="availableCategories"
                    @search="actionSearchCategories"
                    v-model="selectedCategory">
                </v-select>
                <v-select
                    label="name"
                    :filterable="false"
                    :options="availableSubCategories"
                    v-model="selectedSubCategory"
                    v-if="selectedCategory">
                </v-select>
              </dd>
            </div>
            <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">
                Tax
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <v-select
                    label="name"
                    :filterable="false"
                    :options="availableTaxRates"
                    @search="actionSearchTaxRates"
                    v-model="selectedTaxRate">
                </v-select>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AutoComplete from "@/components/AutoComplete";
import {formatMoney} from "accounting-js";
import moment from 'moment-timezone';
import api_server, {GetListConfig} from "@/plugins/api_server";
import Loader from "@/components/Loader";
import _ from 'lodash';

export default {
  name: "ReconciliationItem",
  props: {
    item: {
      type: Object,
      required: true
    },
    record: {
      type: Object,
      required: true
    },
    defaultTaxRates: {
      type: Array,
      default: () => []
    },
    defaultCategories: {
      type: Array,
      default: () => []
    },
    defaultChartAccounts: {
      type: Array,
      default: () => []
    },
    defaultBankAccounts: {
      type: Array,
      default: () => []
    },
  },

  components: {
    Loader,
    AutoComplete
  },

  data: () => {
    return {
      isLoading: false,

      reconcile_type: 'transaction',

      reconcile_form: {
        description: null
      },

      isLoadingContacts: null,
      selectedContact: null,
      searchContacts: null,
      availableContacts: [],

      isLoadingCategories: null,
      selectedCategory: null,
      searchCategories: null,
      availableCategories: [],

      availableSubCategories: [],
      selectedSubCategory: null,


      isLoadingTaxRates: null,
      selectedTaxRate: null,
      searchTaxRates: null,
      availableTaxRates: [],

      isLoadingChartAccounts: false,
      selectedChartAccount: null,
      searchChartAccounts: null,
      availableChartAccounts: [],

      isLoadingBankAccounts: false,
      selectedBankAccount: null,
      availableBankAccounts: [],

    };
  },

  created() {
    this.availableTaxRates = this.defaultTaxRates;
    this.availableCategories = this.defaultCategories;
    this.availableChartAccounts = this.defaultChartAccounts;
    this.availableBankAccounts = this.defaultBankAccounts;
  },

  mounted() {
    let self = this;

    if (this.item.contact) {
      this.selectedContact = this.item.contact.data;
      this.availableContacts.push(this.item.contact.data);
    }

    if (this.item.contact && this.item.contact.data.country) {
      this.reconcile_form.description = this.item.contact.data.country + ' - ' + this.item.transaction_description;
    } else {
      this.reconcile_form.description = this.item.transaction_description;
    }

    if (this.item.account_id) {
      this.selectedChartAccount = this.availableChartAccounts.find(function (acc) {
        return acc.id === self.item.account_id
      })
    }

    // Set the category
    if (this.item.category_1_id) {
      for (let i = 0; i <= this.defaultCategories.length - 1; i++) {
        if (!this.defaultCategories[i].options) {
          continue;
        }


        for (let x = 0; x <= this.defaultCategories[i].options.data.length - 1; x++) {
          if (this.defaultCategories[i].options.data[x].id === this.item.category_1_id) {
            this.selectedCategory = this.defaultCategories[i];
            this.availableSubCategories = this.defaultCategories[i].options.data;
            this.selectedSubCategory = this.defaultCategories[i].options.data[x];
            break;
          }
        }
      }
    }

    if (this.item.tax_rate_id) {
      this.selectedTaxRate = this.availableTaxRates.find(function (acc) {
        return acc.id === self.item.tax_rate_id
      })
    }

    if (this.item.transaction_type === 'Transfer') {
      this.reconcile_type = 'transfer';
    }

    this.$watch('selectedCategory', function (val) {
      if (val == null) {
        this.availableSubCategories = null;
        this.selectedSubCategory = null;
      } else {
        this.availableSubCategories = val.options.data;
        this.selectedSubCategory = null;
      }
    })

    // this.actionSearchTaxRates();
  },

  methods: {
    actionSearchContacts(search, loading) {
      this.debounceContactsInput(search, loading, this);
    },

    actionSearchTaxRates(search, loading) {
      this.debounceTaxRates(search, loading, this);

    },

    actionSearchCategories(search, loading) {
      this.debounceCategories(search, loading, this);
    },

    actionSearchChartAccounts(search, loading) {
      this.debounceChartAccounts(search, loading, this);
    },

    debounceContactsInput: _.debounce((search, loading, vm) => {
      vm.searchContacts = search;
      loading(true);

      vm.getAvailableContacts()
          .then(() => {
            loading(false);
          });
    }, 200),

    debounceChartAccounts: _.debounce((search, loading, vm) => {
      vm.searchChartAccounts = search;
      loading(true);

      vm.getAvailableChartAccounts()
          .then(() => {
            loading(false);
          });
    }, 200),

    debounceCategories: _.debounce((search, loading, vm) => {
      vm.searchCategories = search;
      loading(true);

      vm.getAvailableCategories()
          .then(() => {
            loading(false);
          });
    }, 200),

    debounceTaxRates: _.debounce((search, loading, vm) => {
      vm.searchTaxRates = search;
      loading(true);

      vm.getAvailableTaxRates()
          .then(() => {
            loading(false);
          });
    }, 200),

    getAvailableContacts() {
      this.isLoadingContacts = true;

      return api_server.getClient()
          .then((client) => {
            return client.ListContacts({
              filter: {
                user_integration_settings_id: this.record.id,
                search: this.searchContacts
              }
            }, null, GetListConfig())
                .then((response) => {
                  this.availableContacts = response.data.data;
                  return response.data.data;
                })
                .catch((error) => {
                  throw error;
                })
                .finally(() => {
                  this.isLoadingContacts = false;
                });
          })
    },

    getAvailableCategories() {
      this.isLoadingCategories = true;

      return api_server.getClient()
          .then((client) => {
            return client.ListTrackingCategories({
              filter: {
                user_integration_settings_id: this.record.id
              }
            }, null, GetListConfig())
                .then((response) => {
                  this.availableCategories = response.data.data;
                  return response.data.data;
                })
                .catch((error) => {
                  throw error;
                })
                .finally(() => {
                  this.isLoadingCategories = false;
                });
          })
    },

    getAvailableTaxRates() {
      this.isLoadingTaxRates = true;

      return api_server.getClient()
          .then((client) => {
            return client.ListTaxRates({
              filter: {
                user_integration_settings_id: this.record.id,
                name: this.searchTaxRates
              }
            }, null, GetListConfig())
                .then((response) => {
                  this.availableTaxRates = response.data.data;
                  return response.data.data;
                })
                .catch((error) => {
                  throw error;
                })
                .finally(() => {
                  this.isLoadingTaxRates = false;
                });
          })
    },

    getAvailableChartAccounts() {
      this.isLoadingChartAccounts = true;

      return api_server.getClient()
          .then((client) => {
            return client.ListIntegrationAccounts({
              filter: {
                user_integration_settings_id: this.record.id,
                search: this.searchChartAccounts
              }
            }, null, GetListConfig())
                .then((response) => {
                  this.availableChartAccounts = response.data.data;
                  return response.data.data;
                })
                .catch((error) => {
                  throw error;
                })
                .finally(() => {
                  this.isLoadingChartAccounts = false;
                });
          })
    },

    getAvailableBankAccounts() {
      this.isLoadingBankAccounts = true;

      return api_server.getClient()
          .then((client) => {
            return client.ListIntegrationAccounts({
              filter: {
                user_integration_settings_id: this.record.id,
                account_type: 'BANK'
              }
            }, null, GetListConfig())
                .then((response) => {
                  this.availableBankAccounts = response.data.data;
                  return response.data.data;
                })
                .catch((error) => {
                  throw error;
                })
                .finally(() => {
                  this.isLoadingBankAccounts = false;
                });
          })
    },

    reconcileTransaction() {
      let newReconcileForm = {
        transaction_description: this.reconcile_form.description,
        contact_id: this.selectedContact ? this.selectedContact.id : null,
        account_id: this.selectedChartAccount ? this.selectedChartAccount.id : null,
        category_id: this.selectedCategory ? this.selectedCategory.id : null,
        category_option_id: this.selectedSubCategory ? this.selectedSubCategory.id : null,
        tax_id: this.selectedTaxRate ? this.selectedTaxRate.id : null
      };

      return api_server.getClient()
          .then((client) => {
            return client.ReconcileUserIntegrationRecordOutput(this.item.id, newReconcileForm)
                .then((createdItem) => {
                  this.$emit('reconciled', this.item)
                  return createdItem.data.data
                })
                .catch((error) => {
                  throw error;
                })
                .finally(() => {
                  this.isLoading = false;
                });
          })
    },

    reconcileTransfer() {
      let newReconcileForm = {
        transfer_to_id: this.selectedBankAccount.id,
      };

      return api_server.getClient()
          .then((client) => {
            return client.ReconcileTransferUserIntegrationRecordOutput(this.item.id, newReconcileForm)
                .then((createdItem) => {
                  this.$emit('reconciled', this.item)
                  return createdItem.data.data
                })
                .catch((error) => {
                  throw error;
                })
                .finally(() => {
                  this.isLoading = false;
                });
          })
    },


    reconcileItem() {
      this.isLoading = true;

      let newReconcileForm = {}
      // If this is a deposit
      if (this.reconcile_type === 'transaction') {
        return this.reconcileTransaction();
      } else {
        return this.reconcileTransfer()
      }

    }
  },

  computed: {
    transactionDate() {
      return moment(this.item.transaction_date).format('MMM Do, YYYY hh:mm:ss A')
    },

    transactionTotal() {
      return formatMoney(this.item.transaction_total, {
        symbol: this.item.transaction_currency, format: "%v"
      });
    },

    contact() {
      if (this.item.contact) {
        return this.item.contact.data;
      }

      return null;
    },

    contactName() {
      if (this.contact) {
        return this.contact.name;
      }
    },

    contactEmail() {
      if (this.contact) {
        return this.contact.email
      }
    },

    contactCountry() {
      if (this.contact) {
        return this.contact.country
      }
    },
  }
}
</script>

<style scoped>
img {
  height: auto;
  max-width: 2.5rem;
  margin-right: 1rem;
}

.d-center {
  display: flex;
  align-items: center;
}

.selected img {
  width: auto;
  max-height: 23px;
  margin-right: 0.5rem;
}

.v-select .dropdown li {
  border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.v-select .dropdown li:last-child {
  border-bottom: none;
}

.v-select .dropdown li a {
  padding: 10px 20px;
  width: 100%;
  font-size: 1.25em;
  color: #3c3c3c;
}

.v-select .dropdown-menu .active > a {
  color: #fff;
}
</style>