<template>
  <layout-user-integration-settings
      active-navigation="reports">

    <div class="max-w-7xl mx-auto pb-10 lg:py-12 lg:px-8">

      <div
          class="rounded-lg bg-gray-200 overflow-hidden  divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">

        <div
            class="rounded relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"
            v-for="key in Object.keys(reports)"
            :key="key"
            @click.prevent="() => setReport(key)">

          <div class="mt-1">
            <h3 class="text-lg font-medium">
              <a href="#" class="focus:outline-none">
                <!-- Extend touch target to entire panel -->
                <span class="absolute inset-0" aria-hidden="true"></span>
                {{ getReportProperties(key).name }}
              </a>
            </h3>
            <p class="mt-2 text-sm text-gray-500">
              {{ getReportProperties(key).description }}
            </p>
          </div>
          <div class="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
               aria-hidden="true">
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
              <path
                  d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z"/>
            </svg>
          </div>
        </div>
      </div>


    </div>

  </layout-user-integration-settings>
</template>

<script>
import {mapGetters} from "vuex";
import LayoutUserIntegrationSettings from "@/views/UserIntegrationSettings/Layout";

export default {
  name: "ViewAccountReports",

  components: {
    LayoutUserIntegrationSettings
  },

  data: () => ({
    reports: {
      'balance': {
        name: 'Balance Report',
        description: 'Compare Tea Leaf account balances with other systems',
        navigate: (vm) => {
          vm.$router.push({
            name: 'user_integration_settings_reports_balance',
            params: {
              id: vm.record.id
            }
          })
        }
      }
    }
  }),

  methods: {
    getReportProperties(reportName) {
      return this.reports[reportName]
    },

    setReport(reportName) {
      this.getReportProperties(reportName).navigate(this);
    }
  },

  computed: {
    ...mapGetters({
      isLoading: 'user_integration_settings/isLoading',
      record: 'user_integration_settings/getView'
    })
  }
}
</script>

<style scoped>

</style>