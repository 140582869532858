<template>
  <div class="">
    <div class="bg-white shadow">
      <div class="px-4 sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8">
        <div class="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
          <div class="flex-1 min-w-0">
            <!-- Profile -->
            <div class="flex items-center">
              <img class="hidden h-16 w-16 rounded-full sm:block"
                   :src="user.profile_photo"
                   alt="">
              <div>
                <div class="flex items-center">
                  <img class="h-16 w-16 rounded-full sm:hidden"
                       src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.6&w=256&h=256&q=80"
                       alt="">
                  <h1 class="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                    {{ timeDisplay }}, {{ user.display_name }}
                  </h1>
                </div>
                <dl class="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                  <dt class="sr-only">Company</dt>
                  <dd class="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                    <!-- Heroicon name: office-building -->
                    <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                            d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                            clip-rule="evenodd"/>
                    </svg>

                    {{ user.active_integrations_count }} Integrations
                  </dd>
                  <dt class="sr-only">Account status</dt>
                  <dd class="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
                    <!-- Heroicon name: check-circle -->
                    <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"/>
                    </svg>
                    In Good Standing
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="mt-6 flex space-x-3 md:mt-0 md:ml-4">
          </div>
        </div>
      </div>
    </div>

    <div class="mt-8 px-4 sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8">
      <button type="button"
              class="inline-flex items-center px-4 py-2 border border-transparent mb-10 rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              @click.prevent="goToReport">
        Jump to Reconciliation Report
      </button>
    </div>

    <div class="mt-8 px-4 sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8">
      <div class="pb-5 border-b border-gray-200">
        <div class="-ml-2  flex flex-wrap items-baseline">
          <h3 class="ml-2 mt-2 text-xl leading-6 font-medium text-gray-900">
            System Statistics
          </h3>
          <p class="ml-2 mt-1 text-sm text-gray-500 truncate">last 7 days</p>
        </div>
      </div>


      <dl class="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
        <daily-transaction-total></daily-transaction-total>
        <pending-transactions></pending-transactions>
        <stripe-fees></stripe-fees>
        <transactions-need-reconcile></transactions-need-reconcile>
      </dl>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import DailyTransactionTotal from "@/components/KPIs/DailyTransactionTotal";
import PendingTransactions from "@/components/KPIs/PendingTransactions";
import StripeFees from "@/components/KPIs/StripeFees";
import TransactionsNeedReconcile from "@/components/KPIs/TransactionsNeedReconcile";

export default {
  name: "Dashboard",
  components: {TransactionsNeedReconcile, StripeFees, PendingTransactions, DailyTransactionTotal},
  methods: {

    goToReport() {
      this.$router.push({
        name: 'user_integration_settings_reconciliation',
        params: {
          settings_id: 'fac057da-23f4-4630-883e-2d4da2a3c33c'
        }
      })
    },

  },


  computed: {
    timeDisplay() {
      if (this.userCurrentTime.hour > 5 && this.userCurrentTime < 12) {
        return "Good Morning";
      }

      if (this.userCurrentTime.hour >= 12 && this.userCurrentTime.hour <= 5) {
        return "Good Afternoon"
      }

      return "Good Evening"
    },


    ...mapGetters('authentication', [
      'userCurrentTime',
      'user'
    ])
  }
}
</script>

<style scoped>

</style>