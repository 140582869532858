<template>
  <layout-user-integration-settings
      active-navigation="overview">
    <div class="">
      <div class="" v-if="isLoading">
        <div class="px-4 sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8">
          <div class="py-6 my-6 bg-white rounded" v-if="isLoading">
            <loader></loader>
          </div>
        </div>
      </div>
      <div class="" v-if="!isLoading">
        <div class="">
          <div class="">

            <!-- KPIs -->
            <div class="mt-8 px-4 sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8">
              <div class="pb-5 border-b border-gray-200">
                <div class="-ml-2  flex flex-wrap items-baseline">
                  <h3 class="ml-2 mt-2 text-xl leading-6 font-medium text-gray-900">
                    Temporary Buttons
                  </h3>
                  <p class="ml-2 mt-1 text-sm text-gray-500 truncate">Testing functionality</p>
                </div>
              </div>

              <add-destination-button :record="record"></add-destination-button>
              <import-transactions :record="record"></import-transactions>
              <rerun-transactions :record="record"></rerun-transactions>
            </div>


            <!-- KPIs -->
            <div class="mt-8 px-4 sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8">
              <div class="pb-5 border-b border-gray-200">
                <div class="-ml-2  flex flex-wrap items-baseline">
                  <h3 class="ml-2 mt-2 text-xl leading-6 font-medium text-gray-900">
                    Integration Statistics
                  </h3>
                  <p class="ml-2 mt-1 text-sm text-gray-500 truncate">last 7 days</p>
                </div>
              </div>

              <dl class="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                <daily-transaction-total></daily-transaction-total>
                <pending-transactions></pending-transactions>
                <stripe-fees></stripe-fees>
                <transactions-need-reconcile></transactions-need-reconcile>
              </dl>
            </div>

            <!-- UserIntegrationSettings -->
            <div class="mt-8 px-4 sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8">
              <div class="mt-3 mb-3 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                <h3 class="text-xl leading-6 font-medium text-gray-900">
                  Integrations
                </h3>
                <div class="mt-3 flex sm:mt-0 sm:ml-4">
                </div>
              </div>
              <div class="">
                <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-5">
                  <div class="bg-white shadow overflow-hidden sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                      <h3 class="text-lg leading-6 font-medium text-gray-900">
                        Source Information
                      </h3>
                      <p class="mt-1 max-w-2xl text-sm text-gray-500">
                        Incoming data binding
                      </p>
                    </div>
                    <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
                      <dl class="sm:divide-y sm:divide-gray-200">
                        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">
                            Input Method
                          </dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            Stripe
                          </dd>
                        </div>
                        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">
                            Input Timezone
                          </dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            Australia/Sydney
                          </dd>
                        </div>
                        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">
                            Input Status
                          </dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            Active
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                  <div class="bg-white shadow overflow-hidden sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                      <h3 class="text-lg leading-6 font-medium text-gray-900">
                        Output Information
                      </h3>
                      <p class="mt-1 max-w-2xl text-sm text-gray-500">
                        Outgoing data binding
                      </p>
                    </div>
                    <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
                      <dl class="sm:divide-y sm:divide-gray-200">
                        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">
                            Output Method
                          </dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            Xero
                          </dd>
                        </div>
                        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">
                            Output Timezone
                          </dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            Australia/Sydney
                          </dd>
                        </div>
                        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt class="text-sm font-medium text-gray-500">
                            Output Status
                          </dt>
                          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            Active
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Reconciliation Report -->
            <div class="mt-8 px-4 sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8">
              <div class="mt-3 mb-3 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                <h3 class="text-xl leading-6 font-medium text-gray-900">
                  Reconciliation Report
                </h3>
                <div class="mt-3 flex sm:mt-0 sm:ml-4">
                </div>
              </div>
              <div class="bg-white">
              </div>
            </div>

            <div class="mt-8 px-4 sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8">

              <!-- Raw transaction feeds -->
              <div class="mt-3 mb-3 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                <h3 class="text-xl leading-6 font-medium text-gray-900">
                  Transactions
                </h3>
                <div class="mt-3 flex sm:mt-0 sm:ml-4">
                </div>
              </div>
              <div class="bg-white">
                <div class="flex flex-col">
                  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <div>
                          <div class="sm:hidden">
                            <label for="tabs" class="sr-only">Select a tab</label>
                            <select id="tabs" name="tabs"
                                    class="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
                              <option selected>Output Feed</option>
                              <option>Input Feed</option>
                            </select>
                          </div>
                          <div class="hidden sm:block">
                            <div class="border-b border-gray-200">
                              <nav class="-mb-px flex" aria-label="Tabs">
                                <a href="#"
                                   class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 w-1/2 py-4 px-1 text-center border-b-2 font-medium text-sm">
                                  Output Feed
                                </a>
                                <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
                                <a href="#"
                                   class="border-indigo-500 text-indigo-600 w-1/2 py-4 px-1 text-center border-b-2 font-medium text-sm"
                                   aria-current="page">
                                  Input Feed
                                </a>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout-user-integration-settings>
</template>

<script>

import {mapGetters} from "vuex";
import Loader from "@/components/Loader";
import LayoutUserIntegrationSettings from "@/views/UserIntegrationSettings/Layout";
import ImportTransactions from "@/components/Actions/ImportTransactions";
import AddDestinationButton from "@/components/Actions/AddOutputButton";
import ReconciliationReport from "@/components/Reports/ReconciliationReport";
import TransactionsNeedReconcile from "@/components/KPIs/TransactionsNeedReconcile";
import StripeFees from "@/components/KPIs/StripeFees";
import PendingTransactions from "@/components/KPIs/PendingTransactions";
import DailyTransactionTotal from "@/components/KPIs/DailyTransactionTotal";
import InputRecordList from "@/components/UserInputRecord/InputRecordList";
import RerunTransactions from "@/components/Actions/RerunTransactions";

export default {
  name: "ViewAccount",

  components: {
    LayoutUserIntegrationSettings,
    ImportTransactions,
    AddDestinationButton,
    Loader,
    ReconciliationReport,
    TransactionsNeedReconcile,
    StripeFees,
    PendingTransactions,
    DailyTransactionTotal,
    InputRecordList,
    RerunTransactions
  },

  computed: {
    ...mapGetters({
      isLoading: 'user_integration_settings/isLoading',
      record: 'user_integration_settings/getView'
    })
  }
}
</script>

<style scoped>

</style>