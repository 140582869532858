<template>
  <div class="relative text-left"
       v-click-outside="() => this.dropdownOpen = false">
    <button type="button"
            class="flex items-center text-sm font-medium text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
            id="menu-1" aria-expanded="false" aria-haspopup="true"
            @click.prevent="toggleDropdown">
      <span>{{ getButtonText }}</span>
      <!-- Heroicon name: solid/chevron-down -->
      <svg class="ml-1 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
           fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"/>
      </svg>
    </button>

    <!--
      Dropdown menu, show/hide based on menu state.

      Entering: "transition ease-out duration-100"
        From: "transform opacity-0 scale-95"
        To: "transform opacity-100 scale-100"
      Leaving: "transition ease-in duration-75"
        From: "transform opacity-100 scale-100"
        To: "transform opacity-0 scale-95"
    -->
    <transition
        enter-active-class="transition ease-out duration-100"
        enter-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95">
      <div
          class="origin-top-right absolute z-30 right-0 mt-2 w-60 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu" aria-orientation="vertical" aria-labelledby="menu-1"
          v-if="dropdownOpen">

        <nav class="flex space-x-4 bg-blue-200" aria-label="Tabs">
          <a
              href="#"
              class="px-3 py-2 font-medium text-sm"
              :class="searchType === 'range' ? 'bg-blue-300 text-gray-800' : ''"
              @click.prevent="() => setFilter('range')">
            Range
          </a>
          <a
              href="#"
              class="px-3 py-2 font-medium text-sm"
              :class="searchType === 'exact' ? 'bg-blue-300 text-gray-800' : ''"
              @click.prevent="() => setFilter('exact')">
            Exact
          </a>
        </nav>

        <div class="p-4">
          <form class="bg-white rounded" @submit.prevent="selectOption">

            <div class="" v-if="searchType === 'exact'">
              <div class="mb-4">
                <label class="block mb-2 text-sm font-bold text-gray-700"
                       for="exactDate">
                  Date
                </label>

                <div class="flex">
                  <input
                      class="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                      id="exactDate"
                      type="date"
                      placeholder=""
                      v-model="form[exactDateField]"
                      ref="exact_date"
                  />
                </div>
              </div>
            </div>

            <div class="" v-if="searchType === 'range'">

              <div class="mb-4">
                <label class="block mb-2 text-sm font-bold text-gray-700"
                       for="from_date">
                  From
                </label>

                <div class="flex">
                  <input
                      class="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                      id="from_date"
                      type="date"
                      placeholder=""
                      v-model="form[fromDateField]"
                      ref="from_date"
                  />
                </div>
              </div>

              <div class="mb-4">
                <label class="block mb-2 text-sm font-bold text-gray-700"
                       for="to_date">
                  To
                </label>

                <div class="flex">
                  <input
                      class="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                      id="to_date"
                      type="date"
                      placeholder=""
                      v-model="form[toDateField]"
                      ref="to_date"
                  />
                </div>
              </div>

            </div>


            <button type="submit"
                    class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full">
              Apply
            </button>
          </form>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  name: "FilterDateRange",

  props: {
    name: {
      type: String,
      required: true,
    },
    text: {
      type: String
    },
    value: {
      types: [Object, null],
      required: true
    }
  },

  directives: {
    ClickOutside
  },

  data: () => ({
    form: {},
    searchType: 'range',
    dropdownOpen: false
  }),

  mounted() {
    if (this.value[this.exactDateField]) {
      this.setFilter('exact')
    } else {
      this.setFilter('range')
    }

    this.$watch('value', (form) => {
      this.form = form;
    })
  },

  methods: {
    setFilter(filter) {
      if (filter === 'exact') {
        this.form[this.exactDateField] = this.exactDateValue || null;
        this.form[this.fromDateField] =  null;
        this.form[this.toDateField] =  null;

      } else {
        this.form[this.fromDateField] = this.fromDateValue || null;
        this.form[this.toDateField] = this.toDateValue || null;
        this.form[this.exactDateField] = null;
      }

      this.searchType = filter;
    },

    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen
    },

    selectOption() {
      this.dropdownOpen = false;
      console.log('New date input', this.form)
      this.$emit('input', this.form);
    },
  },

  computed: {
    getButtonText() {
      let options = []

      if (this.exactDateValue) {
        options.push(this.exactDateValue);
      } else {
        if (this.fromDateValue) {
          options.push(this.fromDateValue);
        }

        if (this.toDateValue) {
          options.push(this.toDateValue)
        }
      }

      if (options.length > 0) {
        return options.join(' - ');
      } else {
        if (this.text) {
          return this.text ;
        } else {
          return this.name;
        }
      }
    },

    exactDateValue() {
      return  this.value[this.exactDateField];
    },

    fromDateValue() {
      return this.value[this.fromDateField];
    },

    toDateValue() {
      return this.value[this.toDateField];
    },

    exactDateField() {
      return this.name;
    },

    fromDateField() {
      return this.name + '__after';
    },

    toDateField() {
      return this.name + '__before';
    }
  }
}
</script>

<style scoped>

</style>