<template>
  <div class="">
    <contact-filters
      @filters-changed="filtersUpdated">
    </contact-filters>

    <div v-if="isLoading">
      <div class="mt-5">
        <loader></loader>
      </div>
    </div>
    <slot v-if="!isLoading && list.data.length > 0">
      <div class="min-w-full">
        <pagination @page="setPage" :meta="list.meta"></pagination>
      </div>
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="overflow-hidden border-t border-gray-200">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
              <tr>
                <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  E-mail
                </th>
                <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Country
                </th>
                <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase text-center tracking-wider">
                  Source
                </th>
                <th scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase text-center tracking-wider">
                  Destination
                </th>
                <th scope="col" class="relative px-6 py-3 text-right">
                  <span class="sr-only">View</span>
                </th>
              </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
              <tr
                  class="cursor-pointer"
                  v-for="item in list.data"
                  @click.prevent="() => rowClicked(item)">
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="flex-shrink-0 h-10 w-10">
                      <img class="h-10 w-10 rounded-full"
                           :src="item.photo_url"
                           alt="">
                    </div>
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ item.email }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ getUserName(item) }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ item.country }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class=" flex justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 20 20"
                         fill="currentColor"
                         aria-hidden="true"
                         class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" v-if="item.source_id">
                      <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fad"
                        data-icon="times-circle"
                        class="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512" v-else>
                      <g class="fa-group">
                        <path class="fa-secondary" fill="currentColor"
                              d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1a12 12 0 0 1 0 17L338 377.6a12 12 0 0 1-17 0L256 312l-65.1 65.6a12 12 0 0 1-17 0L134.4 338a12 12 0 0 1 0-17l65.6-65-65.6-65.1a12 12 0 0 1 0-17l39.6-39.6a12 12 0 0 1 17 0l65 65.7 65.1-65.6a12 12 0 0 1 17 0l39.6 39.6a12 12 0 0 1 0 17L312 256z"
                              opacity="0.4"></path>
                        <path class="fa-primary" fill="currentColor"
                              d="M377.6 321.1a12 12 0 0 1 0 17L338 377.6a12 12 0 0 1-17 0L256 312l-65.1 65.6a12 12 0 0 1-17 0L134.4 338a12 12 0 0 1 0-17l65.6-65-65.6-65.1a12 12 0 0 1 0-17l39.6-39.6a12 12 0 0 1 17 0l65 65.7 65.1-65.6a12 12 0 0 1 17 0l39.6 39.6a12 12 0 0 1 0 17L312 256z"></path>
                      </g>
                    </svg>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class=" flex justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 20 20"
                         fill="currentColor"
                         aria-hidden="true"
                         class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" v-if="item.destination_id">
                      <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fad"
                        data-icon="times-circle"
                        class="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512" v-else>
                      <g class="fa-group">
                        <path class="fa-secondary" fill="currentColor"
                              d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1a12 12 0 0 1 0 17L338 377.6a12 12 0 0 1-17 0L256 312l-65.1 65.6a12 12 0 0 1-17 0L134.4 338a12 12 0 0 1 0-17l65.6-65-65.6-65.1a12 12 0 0 1 0-17l39.6-39.6a12 12 0 0 1 17 0l65 65.7 65.1-65.6a12 12 0 0 1 17 0l39.6 39.6a12 12 0 0 1 0 17L312 256z"
                              opacity="0.4"></path>
                        <path class="fa-primary" fill="currentColor"
                              d="M377.6 321.1a12 12 0 0 1 0 17L338 377.6a12 12 0 0 1-17 0L256 312l-65.1 65.6a12 12 0 0 1-17 0L134.4 338a12 12 0 0 1 0-17l65.6-65-65.6-65.1a12 12 0 0 1 0-17l39.6-39.6a12 12 0 0 1 17 0l65 65.7 65.1-65.6a12 12 0 0 1 17 0l39.6 39.6a12 12 0 0 1 0 17L312 256z"></path>
                      </g>
                    </svg>
                  </div>
                </td>

                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <a href="#" class="text-indigo-600 hover:text-indigo-900">View</a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="min-w-full">
        <pagination @page="setPage" :meta="list.meta"></pagination>
      </div>
    </slot>

    <slot name="no-results" v-if="!isLoading && list.data.length === 0">
      <h1>No results</h1>
    </slot>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Loader from "@/components/Loader";
import Pagination from "@/components/Pagination/Pagination";
import ContactFilters from "@/components/UserIntegrationSettingsContact/ContactFilters";

export default {
  name: "ListUserIntegrationSettingsContacts",

  components: {
    ContactFilters,
    Pagination,
    Loader
  },

  props: {
    inputFilters: {
      type: Object
    },
  },

  created() {
    this.loadList()
  },

  data: () => ({
    searchForm: {},
  }),

  methods: {
    filtersUpdated(filter) {
      this.searchForm = {filter};
      this.loadList();
    },

    setPage(page) {
      return this.loadList({
        page: {
          number: page
        }
      })
    },

    getUserName(user) {
      let finalName = ''

      if (user.last_name && user.first_name) {
        finalName = `${user.last_name}, ${user.first_name}`
      } else if (user.last_name && !user.first_name) {
        finalName = user.last_name
      } else if (user.first_name) {
        finalName = user.first_name
      }

      return finalName;
    },

    loadList(params = {}) {
      let filterParams = Object.assign(
          {},
          this.inputFilters,
          this.searchForm,
          params
      );

      this.$store.dispatch(
          'user_integration_settings_contacts/readList',
          filterParams
      )
    },

    rowClicked(item) {
      this.$router.push({
        name: 'View Contact',
        params: {
          'id': item.id
        }
      });
    },
  },

  computed: {
    ...mapGetters({
      isLoading: 'user_integration_settings_contacts/getIsLoadingList',
      list: 'user_integration_settings_contacts/getList'
    })
  }
}
</script>

<style scoped>

</style>