<template>
        <h1>Activities</h1>
</template>

<script>
    export default {
        name: "Activities",
    }
</script>

<style scoped>

</style>