<template>
  <layout-user-integration-settings
      active-navigation="reconciliation">

    <div class="mt-8 px-4 sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8">
      <!-- List Results -->
      <section aria-labelledby="billing_history_heading">
        <div class="bg-white py-6 shadow sm:rounded-md sm:overflow-hidden">
          <div class="px-4 sm:px-6 md:flex md:items-center md:justify-between">
            <div class="flex-1 min-w-0">
              <!-- Profile -->
              <div class="flex items-center">
                <div>
                  <div class="flex items-center">
                    <h2 id="billing_history_heading" class="text-lg leading-6 font-medium text-gray-900">
                      Reconciliation Report
                    </h2>
                  </div>
                  <div class="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                    <div class="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                      <p>
                        Reconcile Transactions before they are deposited into the destination account
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-6 flex space-x-3 md:mt-0 md:ml-4">
            </div>
          </div>
        </div>
      </section>

      <reconciliation-report
          :input-filters="getInputFilters"
          :record="record">
        <template slot="no-results">
          <div
              class="p-8 bg-white rounded shadow-md flex flex-col align-middle justify-center items-center">
            <h2 class="text-3xl mb-4">No items to reconcile</h2>
          </div>
        </template>
      </reconciliation-report>

      <!--Modal-->
      <create-user-integration-settings-contact
          :show-modal="showCreateModal"
          :initial-data="{user_integration_settings_id: record.id}"
          @created="createdItem"
          @closed="toggleModal">
      </create-user-integration-settings-contact>
    </div>

  </layout-user-integration-settings>
</template>

<script>
import {mapGetters} from "vuex";
import LayoutUserIntegrationSettings from "@/views/UserIntegrationSettings/Layout";
import ReconciliationReport from "@/components/Reports/ReconciliationReport";
import ListUserIntegrationRecordInput from "@/components/UserIntegrationRecordInputs/ListUserIntegrationRecordInput";
import CreateUserIntegrationSettingsContact
  from "@/components/UserIntegrationSettingsContact/CreateUserIntegrationSettingsContact";
import ListUserIntegrationSettingsContacts
  from "@/components/UserIntegrationSettingsContact/ListUserIntegrationSettingsContacts";

export default {
  name: "ViewAccountReconciliation",

  components: {
    ReconciliationReport,
    ListUserIntegrationRecordInput,
    CreateUserIntegrationSettingsContact,
    ListUserIntegrationSettingsContacts,
    LayoutUserIntegrationSettings
  },

  data: () => {
    return {
      showCreateModal: false,
    }
  },

  methods: {
    createdItem(created_object) {
      this.$router.push({
        name: 'user_integration_settings_view',
        params: {
          'id': created_object.data.id
        }
      })
    },

    toggleModal() {
      this.showCreateModal = !this.showCreateModal
    },
  },

  computed: {
    getInputFilters() {
      return {
        filter: {
          user_integration_settings_id: this.record.id
        }
      }
    },

    ...mapGetters({
      isLoading: 'user_integration_settings/isLoading',
      record: 'user_integration_settings/getView'
    }),

  }
}
</script>

<style scoped>

</style>