<template>
  <layout-user-integration-settings
      active-navigation="outputFeed">
    <div class="">

      <div class="" v-if="isLoading">
        <div class="px-4 sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8">
          <div class="py-6 my-6 bg-white rounded" v-if="isLoading">
            <loader/>
          </div>
        </div>
      </div>

      <div class="" v-if="!isLoading">
        <div class="">
          <div class="">

            <!-- KPIs -->
            <div class="mt-8 px-4 sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8">
              <div class="pb-5 border-b border-gray-200">
                <div class="-ml-2  flex flex-wrap items-baseline">
                  <h3 class="ml-2 mt-2 text-xl leading-6 font-normal text-gray-900">
                    User Integration Record Output
                  </h3>
                  <p class="ml-2 mt-1 text-sm text-gray-500 truncate">
                    A record which will be sent to the destination account
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-8 px-4 sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8">
          <div
              class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div class="space-y-6 lg:col-start-1 lg:col-span-2">
              <!-- Description list-->
              <section aria-labelledby="applicant-information-title">
                <div class="bg-white shadow sm:rounded-lg">
                  <div class="px-4 py-5 sm:px-6">
                    <h2 id="applicant-information-title" class="text-lg leading-6 font-normal text-gray-900">
                      Transaction Information
                    </h2>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">
                      Information about this record
                    </p>
                  </div>
                  <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                      <div class="sm:col-span-1">
                        <dt class="text-sm text-gray-500">
                          Integration Settings
                        </dt>
                        <dd class="mt-1 text-lg font-normal text-blue-600 truncate">
                          <a href="#" class="hover:underline" @click.prevent="onClickIntegrationSettings">
                            {{ getIntegrationSettingsTitle() }}
                          </a>
                        </dd>
                      </div>
                      <div class="sm:col-span-1">
                        <dt class="text-sm text-gray-500">
                          Transaction Total
                        </dt>
                        <dd class="text-lg font-normal text-gray-900 truncate">
                          ${{ getTransactionTotal() }}
                        </dd>
                      </div>
                      <div class="sm:col-span-1">
                        <dt class="text-sm text-gray-500">
                          Output Record
                        </dt>
                        <dd class="text-lg font-normal text-gray-900 truncate">
                          <div class="" v-if="shouldShowOutput()">
                            <a href="#" @click.prevent="onClickOutput" v-if="shouldShowOutput()">
                              {{ getIntegrationSettingsTitle() }}
                            </a>
                          </div>
                          <div class="font-normal" v-else>
                            Generating Output...
                          </div>
                        </dd>
                      </div>
                      <div class="sm:col-span-3">
                        <dt class="text-sm text-gray-500">
                          Transaction Description
                        </dt>
                        <dd class="text-lg font-normal text-gray-900 truncate">
                          {{ getTransactionDescription() }}
                        </dd>
                      </div>
                      <div class="sm:col-span-3">
                        <dt class="text-sm text-gray-500">
                          Transaction Date
                        </dt>
                        <dd class="text-lg font-normal text-gray-900 truncate">
                          {{ getTransactionDate() }}
                        </dd>
                      </div>
                      <div class="sm:col-span-1">
                        <dt class="text-sm text-gray-500">
                          Source ID
                        </dt>
                        <dd class="text-lg font-normal text-gray-900 truncate">
                          {{ getSourceId() }}
                        </dd>
                      </div>
                      <div class="sm:col-span-1">
                        <dt class="text-sm text-gray-500">
                          Transaction Type
                        </dt>
                        <dd class="text-lg font-normal text-gray-900 truncate">
                          {{ getTransactionType() }}
                        </dd>
                      </div>
                      <div class="sm:col-span-1">
                        <dt class="text-sm text-gray-500">
                          Contact
                        </dt>
                        <dd class="text-lg font-normal text-gray-900 truncate">
                          <a href="#" @click.prevent="onClickContact" v-if="shouldShowContact()">
                            {{ getContactName() }}
                          </a>
                        </dd>
                      </div>
                      <div class="sm:col-span-1">
                        <dt class="text-sm text-gray-500">
                          Created At
                        </dt>
                        <dd class="text-lg font-normal text-gray-900 truncate">
                          {{ getCreatedDate() }}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </section>
            </div>

            <section aria-labelledby="timeline-title" class="lg:col-start-3 lg:col-span-1">
              <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                <h2 id="timeline-title" class="text-lg font-normal text-gray-900 truncate">

                </h2>

                <!-- Activity Feed -->
                <timeline :timeline="getTimeline()"></timeline>

                <div class="mt-6 flex flex-col justify-stretch">
                  <button type="button"
                          class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-normal rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          @click.prevent="onClickOutput">
                    Go to Input Record
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </layout-user-integration-settings>
</template>

<script>
import {mapGetters} from "vuex";
import LayoutUserIntegrationSettings from "@/views/UserIntegrationSettings/Layout";
import Loader from "@/components/Loader";
import Timeline from "@/components/Timeline/Timeline";
import {formatMoney} from "accounting-js";

export default {
  name: "ViewAccountOutput",

  components: {
    Timeline,
    Loader,
    LayoutUserIntegrationSettings
  },

  props: {},

  data: () => ({}),

  mounted() {
    this.loadRecord();
  },

  methods: {
    loadRecord() {
      this.$store.dispatch(
          "user_integration_record_outputs/readItem",
          this.$route.params.output_id
      ).then((item) => {

        // Get the contact
        this.$store.dispatch(
            "user_integration_settings_contacts/readItem",
            item.contact_id
        )

        // Get the output
        this.$store.dispatch(
            "user_integration_record_inputs/readItem",
            item.user_integration_record_input_id
        )
      })
    },

    getSourceURL() {
      return 'https://dashboard.stripe.com/search?query=' + this.outputRecord.source_id;
    },

    onClickIntegrationSettings() {
      return this.$router.push({
        name: 'user_integration_settings_view',
        params: {
          settings_id: this.outputRecord.user_integration_settings_id
        }
      });
    },

    onClickOutput() {
      return this.$router.push({
        name: 'user_integration_settings_view_output',
        params: {
          settings_id: this.outputRecord.user_integration_settings_id,
          output_id: this.outputRecord.user_integration_record_output_id
        }
      });
    },

    getTransactionTotal() {
      return formatMoney(this.outputRecord.transaction_total, {symbol: 'AUD', format: "%v"})
    },

    getIntegrationSettingsTitle() {
      return this.settingsRecord.title;
    },

    getOutputTitle() {
      return this.settingsRecord.transaction_description;
    },

    getCreatedDate() {
      return this.outputRecord.created_at
    },

    getTransactionDate() {
      return this.outputRecord.transaction_date
    },

    shouldShowOutput() {
      return this.outputRecord.user_integration_record_output_id
    },

    getTransactionDescription() {
      return this.outputRecord.transaction_description;
    },

    getSourceId() {
      return this.outputRecord.source_id
    },

    getTransactionType() {
      return this.outputRecord.transaction_type;
    },

    getContactName() {
      return this.contactRecord.email
    },

    onClickContact() {
      return this.$router.push({
        name: 'user_integration_settings_contacts',
        params: {
          settings_id: this.outputRecord.user_integration_settings_id,
          contact_id: this.outputRecord.contact_id
        }
      });
    },

    shouldShowContact() {
      return this.contactRecord
    },

    getTimeline() {
      return [
        {
          text: "Created from Input Record",
          status: 'Finished',
          date: this.getCreatedDate(),
          link: {
            text: 'View Input',
            url: (item) => this.$router.push({
              name: 'user_integration_settings_view_input',
              params: {
                settings_id: item.user_integration_settings_id,
                input_id: item.user_integration_record_input_id
              }
            }),
            show: true
          }
        },
      ]
    }
  },

  computed: {
    isLoading() {
      return this.isLoadingInput ||
          this.isLoadingOutput ||
          this.isLoadingContact ||
          this.isLoadingSettings
    },

    ...mapGetters({
      isLoadingSettings: 'user_integration_settings/getIsLoadingItem',
      isLoadingInput: 'user_integration_record_inputs/getIsLoadingItem',
      isLoadingOutput: 'user_integration_record_outputs/getIsLoadingItem',
      isLoadingContact: 'user_integration_settings_contacts/getIsLoadingItem',

      settingsRecord: 'user_integration_settings/getView',
      outputRecord: 'user_integration_record_outputs/getView',
      inputRecord: 'user_integration_record_inputs/getView',
      contactRecord: 'user_integration_settings_contacts/getView',
      accountRecord: ''
    })
  }
}
</script>

<style scoped>

</style>