<template>
  <div class="mt-6 flow-root">
    <ul class="-mb-8">

      <li v-for="(item, index) of timeline" :key="index">
        <div class="relative pb-8">
          <span
              class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
              aria-hidden="true"
              v-if="index !== timeline.length - 1"></span>

          <div class="relative flex space-x-3">

            <!-- Icon Configuration -->
            <div v-if="item.status === 'Pending'">

              <span
                  class="h-8 w-8 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white">
                <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fal"
                    data-icon="spinner"
                    class="w-5 h-5 text-white vg-inline--fa fa-spinner animate-spin"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512">
                  <path fill="currentColor"
                        d="M288 32c0 17.673-14.327 32-32 32s-32-14.327-32-32 14.327-32 32-32 32 14.327 32 32zm-32 416c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32zm256-192c0-17.673-14.327-32-32-32s-32 14.327-32 32 14.327 32 32 32 32-14.327 32-32zm-448 0c0-17.673-14.327-32-32-32S0 238.327 0 256s14.327 32 32 32 32-14.327 32-32zm33.608 126.392c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32zm316.784 0c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32zM97.608 65.608c-17.673 0-32 14.327-32 32 0 17.673 14.327 32 32 32s32-14.327 32-32c0-17.673-14.327-32-32-32z"></path>
                </svg>
              </span>
            </div>


            <div v-if="item.status === 'Finished'">
              <span
                  class="h-8 w-8 rounded-full bg-green-500 flex items-center justify-center ring-8 ring-white">
                <svg class="w-5 h-5 text-white"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                     aria-hidden="true">
                  <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"></path>
                </svg>
              </span>
            </div>

            <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
              <div>
                <p class="text-sm text-gray-500">
                  {{ getText(item) }}
                </p>
                <a
                    href="#"
                    class="font-medium text-gray-900"
                    @click.prevent="onClickedLink(item)"
                    v-if="shouldShow(item)">
                  {{ getLinkText(item) }}
                </a>
              </div>
              <div class="text-right text-sm whitespace-nowrap text-gray-500">
                <time :datetime="item.date" v-if="item.status !== 'Pending'">
                  {{ formatDate(item) }}
                </time>
              </div>
            </div>
          </div>
        </div>
      </li>

    </ul>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "Timeline",
  props: {
    // link: {url: 'http://'|(click) => {}, text: '', show=True|False}
    // link:
    // Example is:
    // [{text: '', status: 'Pending|Finished|Failed]', date: '2021-05-31 00:00:00', link}]
    'timeline': {
      type: Array,
      required: true
    }
  },

  methods: {
    onClickedLink(item) {
      if (item.link.url instanceof Function) {
        return item.link.url(item);
      }

      // Redirect
      //return location.href = item.link.url;

      // Open new tab
      window.open(item.link.url);
    },

    shouldShow(item) {
      if (item.link.show instanceof Function) {
        return item.link.show(item);
      }

      // Open new tab
      return item.link.show;
    },

    getText(item) {
      if (item.text instanceof Function) {
        return item.text(item);
      }

      return item.text;
    },

    getLinkText(item) {
      if (item.link.text instanceof Function) {
        return item.link.text(item);
      }

      return item.link.text;
    },

    formatDate(item) {
      let dateFormat = 'MMM D';
      const parsedDate = moment(item.date);

      if (moment().year() !== parsedDate.year()) {
        dateFormat += ' YY';
      }

      return parsedDate.format(dateFormat);
    }
  }
}
</script>

<style scoped>

</style>