<template>
  <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
    <div class="flex-1 flex justify-between sm:hidden">
      <a href="#"
         class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
        Previous
      </a>
      <a href="#"
         class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
        Next
      </a>
    </div>
    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
      <div>
        <p class="text-sm text-gray-700">
          Showing
          <span class="font-medium">{{ meta.from }}</span>
          to
          <span class="font-medium">{{ meta.to }}</span>
          of
          <span class="font-medium">{{ meta.total }}</span>
          results
        </p>
      </div>


      <div class="flex">
        <div class="mr-3 ">
          <select
              id="country"
              name="country"
              class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
              :value="meta.current_page"
              @input="(e) => emitPage(e.target.value)"
          >
            <option
                v-for="page in getPageArray()"
                :value="page"
                :key="page">
              {{ page }}
            </option>
          </select>
        </div>
        <nav class="flex-1 relative z-0 inline-flex shadow-sm -space-x-px" aria-label="Pagination">

          <a
              href="#"
              class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              @click.prevent="firstPage">
            <span class="sr-only">First</span>

            <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="chevron-double-left" class="h-5 w-5"
                 role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <g class="fa-group">
                <path class="fa-secondary" fill="currentColor"
                      d="M226.56 239l194-194a23.84 23.84 0 0 1 33.73-.1l.1.1L477 67.7a24.06 24.06 0 0 1 0 33.85L323.36 256l153.58 154.45a23.87 23.87 0 0 1 .1 33.75l-.1.1-22.65 22.7a23.84 23.84 0 0 1-33.73.1l-.1-.1-193.9-194a24.17 24.17 0 0 1 0-34z"
                      opacity="0.4"></path>
                <path class="fa-primary" fill="currentColor"
                      d="M35 239L229 45a23.84 23.84 0 0 1 33.73-.1l.1.1 22.61 22.7a23.87 23.87 0 0 1 .1 33.75l-.1.1L131.76 256l153.68 154.45a24.06 24.06 0 0 1 0 33.85L262.79 467a23.84 23.84 0 0 1-33.73.1l-.1-.1L35 273a24.17 24.17 0 0 1 0-34z"></path>
              </g>
            </svg>

          </a>
          <a
              href="#"
              class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              @click.prevent="previousPage">
            <span class="sr-only">Previous</span>
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                 aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clip-rule="evenodd"/>
            </svg>
          </a>
          <a
              href="#"
              class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              @click.prevent="nextPage">
            <span class="sr-only">Next</span>
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                 aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"/>
            </svg>
          </a>
          <a
              href="#"
              class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              @click.prevent="lastPage">
            <span class="sr-only">Last</span>

            <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="chevron-double-right" class="h-5 w-5"
                 role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <g class="fa-group">
                <path class="fa-secondary" fill="currentColor"
                      d="M285.6 273L90.79 467a24 24 0 0 1-33.88.1l-.1-.1-22.74-22.7a24 24 0 0 1 0-33.85L188.39 256 34.07 101.55A23.8 23.8 0 0 1 34 67.8l.11-.1L56.81 45a24 24 0 0 1 33.88-.1l.1.1L285.6 239a24.09 24.09 0 0 1 0 34z"
                      opacity="0.4"></path>
                <path class="fa-primary" fill="currentColor"
                      d="M478 273L283.19 467a24 24 0 0 1-33.87.1l-.1-.1-22.75-22.7a23.81 23.81 0 0 1-.1-33.75l.1-.1L380.8 256 226.47 101.55a24 24 0 0 1 0-33.85L249.22 45a24 24 0 0 1 33.87-.1.94.94 0 0 1 .1.1L478 239a24.09 24.09 0 0 1 0 34z"></path>
              </g>
            </svg>
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pagination",

  props: {
    meta: {
      type: Object,
      default: () => ({})
    }
  },

  methods: {
    getPageArray() {
      let pageArray = [];

      for (let i = 1; i <= this.meta.last_page; i++) {
        pageArray.push(i);
      }

      return pageArray;
    },

    nextPage() {
      let thisPage = this.meta.current_page;
      let lastPage = this.meta.last_page
      let nextPage = thisPage += 1;

      if (nextPage > lastPage) {
        nextPage = lastPage;
      }

      this.emitPage(nextPage)
    },
    lastPage() {
      this.emitPage(this.meta.last_page)
    },
    firstPage() {
      this.emitPage(1);
    },

    emitPage(page) {
      console.log(page);
      this.$emit('page', page);
    },

    previousPage() {
      let thisPage = this.meta.current_page;
      let nextPage = thisPage -= 1;

      if (nextPage < 1) {
        nextPage = 1
      }

      this.emitPage(nextPage)
    },

    goToPage(page) {
      this.emitPage(page)
    }
  }
}
</script>

<style scoped>

</style>