<template>
  <button type="button" class="mr-2 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click.prevent="addSource">
    <span class="" v-if="isAddingOutput">
      Loading ...
    </span>
    <span class="" v-else>
      Add Output
    </span>
  </button>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AddDestinationButton",

  props: {
    record: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    createForm: {
      output_type: 'Xero'
    }
  }),

  methods: {
    addSource() {
      this.$store.dispatch(
          'user_integration_settings/addOutput',
          {
            id: this.record.id,
            data: this.createForm
          }
      ).then((created_object) => {
          this.$emit('created', created_object)
        })
    }
  },

  computed: {
    ...mapGetters({
      isAddingOutput: 'user_integration_settings/getIsAddingOutput'
    }),

    createErrors() {
      return this.$store.state.user_integration_settings.addOutputError;
    }
  }
}
</script>

<style scoped>

</style>