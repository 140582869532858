<template>
  <div class="">
    <div class="py-6 my-6 bg-white rounded" v-if="isLoading">
      <loader></loader>
    </div>

    <div class="">
      <slot name="buttons" v-if="!isLoading"></slot>
    </div>

    <slot v-if="!isLoading && list.length > 0">
      <table class="w-full text-md bg-white shadow-md rounded mb-4">
        <thead>
        <tr>
          <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
            Type
          </th>
          <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
            Description
          </th>
          <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
            Total
          </th>
          <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
            Date
          </th>
          <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
            Status
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            class="bg-white cursor-pointer hover:bg-gray-100"
            v-for="item in list">

          <td class="px-5 py-5 border-b border-gray-200 text-sm">
            <div class="flex items-center">
              <div class="ml-3">
                <p class="text-gray-900 whitespace-no-wrap">
                  {{ item.transaction_type }}
                </p>
              </div>
            </div>
          </td>

          <td class="px-5 py-5 border-b border-gray-200 text-sm">
            <div class="flex items-center">
              <div class="ml-3">
                <p class="text-gray-900 whitespace-no-wrap">
                  {{ item.transaction_description }}
                </p>
              </div>
            </div>
          </td>


          <td class="px-5 py-5 border-b border-gray-200 text-sm">
            <div class="flex items-center">
              <div class="ml-3">
                <p class="text-gray-900 whitespace-no-wrap">
                  {{ item.transaction_total }}
                </p>
              </div>
            </div>
          </td>

          <td class="px-5 py-5 border-b border-gray-200 text-sm">
            <div class="flex items-center">
              <div class="ml-3">
                <p class="text-gray-900 whitespace-no-wrap">
                  {{ item.transaction_date }}
                </p>
              </div>
            </div>
          </td>

          <td class="px-5 py-5 border-b border-gray-200 text-sm">
            <div class="flex items-center">
              <div class="ml-3">
                <p class="text-gray-900 whitespace-no-wrap">
                  Pending
                </p>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </slot>

    <slot name="no-results" v-if="!isLoading && list.length === 0">
      <h1>No results</h1>
    </slot>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Loader from "@/components/Loader";

export default {
  name: "InputRecordList",

  components: {Loader},

  props: [
      'parentId'
  ],

  created() {
    this.loadList()
  },

  methods: {
    loadList() {
      this.$store.dispatch('user_integration_inputs/readList', this.$route.params.settings_id)
    },

    rowClicked(item) {
    },
  },

  computed: {
    ...mapGetters({
      isLoading: 'user_integration_inputs/getIsLoadingList',
    })
  }
}
</script>

<style scoped>

</style>