<template>
  <div class="mt-2 grid grid-cols-4 gap-6">
    <div class="py-4 sm:py-5 col-span-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
      <dt class="text-sm font-medium text-gray-500" id="timezone-option-label">
        {{ text }}
      </dt>
      <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">

        <button type="button"
                class="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-auto"
                :class="buttonClass"
                aria-pressed="true" aria-labelledby="timezone-option-label"
                @click.prevent="toggleCheckbox">
          <span class="sr-only">{{ text }}</span>

          <span aria-hidden="true"
                class="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                :class="spanClass"></span>
        </button>
      </dd>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormToggle",

  props: {
    name: {
      type: String,
      required: true,
    },
    text: {
      type: String
    },
    value: {
      types: [Object, null],
      required: true
    }
  },

  methods: {
    setValue(value) {
      this.$emit('input', value);
    },

    toggleCheckbox() {
      let obj = {};
      obj[this.fieldName] = !this.valueFromForm;

      this.setValue(obj);
    },
  },

  computed: {
    spanClass() {
      return {
        'translate-x-5': this.valueFromForm,
        'translate-x-0': !this.valueFromForm
      }
    },

    buttonClass() {
      return {
        'bg-purple-600': this.valueFromForm,
        'bg-gray-200': !this.valueFromForm
      }
    },

    // Assume this is a boolean? what?
    valueFromForm() {
      return  this.value[this.fieldName];
    },

    fieldName() {
      return this.name;
    },

  }
}
</script>

<style scoped>

</style>