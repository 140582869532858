<template>
  <div class="bg-white overflow-hidden shadow rounded-lg">
    <div class="px-4 py-5 sm:p-6">
      <div class="flex items-center">
        <div class="flex-shrink-0 bg-indigo-500 rounded-md p-3">
          <svg class="h-4 w-4 text-white" x-description="Heroicon name: users" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"></path>
          </svg>
        </div>
        <div class="ml-5 w-0 flex-1">
          <dt class="text-sm font-medium text-gray-500 truncate">
            Needs Reconcile
          </dt>
          <dd class="flex items-baseline">
            <div class="" v-if="isLoading">
              <loader extra-class="h-10 w-10"></loader>
            </div>
            <div class="text-2xl font-semibold text-gray-900" v-if="!isLoading">
              {{ getValue }}
            </div>

<!--            <div class="ml-2 flex items-baseline text-sm font-semibold text-green-600">-->
<!--              <svg class="self-center flex-shrink-0 h-5 w-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">-->
<!--                <path fill-rule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd"></path>-->
<!--              </svg>-->
<!--              <span class="sr-only">-->
<!--                        Increased by-->
<!--                      </span>-->
<!--              122-->
<!--            </div>-->
          </dd>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api_server, {GetListConfig} from "@/plugins/api_server";
import {formatMoney} from "accounting-js";
import Loader from "@/components/Loader";

export default {
  name: "TransactionsNeedReconcile",

  components: {Loader},

    data: () => ({
      isLoading: false,
      value: 0
    }),

    mounted() {
      this.getData();
    },

    methods: {
      getData()  {
        this.isLoading = true;

        return api_server.getClient()
            .then((client) => {
              return client.ReportReconcileTotal(null, null, GetListConfig())
                  .then((response) => {
                    this.value = response.data
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => {
                    this.isLoading = false;
                  });
            })
      },
  },

  computed: {
    getValue() {
        return formatMoney(this.value, {symbol: 'AUD', format: "%v"});
    }
  }

}
</script>

<style scoped>

</style>