<template>
  <div class="">
    <div v-if="isLoading">
      <loader></loader>
    </div>

    <slot v-if="!isLoading && list.length > 0">
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                <tr>
                  <th scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Flags
                  </th>
                </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">

                <tr
                    class="cursor-pointer"
                    v-for="item in list"
                    @click.prevent="() => rowClicked(item)">
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="flex-shrink-0 w-10 h-10 rounded bg-purple-700 p-1">
                        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="stripe"
                             class="w-full h-full text-white"
                             role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                          <path fill="currentColor"
                                d="M165 144.7l-43.3 9.2-.2 142.4c0 26.3 19.8 43.3 46.1 43.3 14.6 0 25.3-2.7 31.2-5.9v-33.8c-5.7 2.3-33.7 10.5-33.7-15.7V221h33.7v-37.8h-33.7zm89.1 51.6l-2.7-13.1H213v153.2h44.3V233.3c10.5-13.8 28.2-11.1 33.9-9.3v-40.8c-6-2.1-26.7-6-37.1 13.1zm92.3-72.3l-44.6 9.5v36.2l44.6-9.5zM44.9 228.3c0-6.9 5.8-9.6 15.1-9.7 13.5 0 30.7 4.1 44.2 11.4v-41.8c-14.7-5.8-29.4-8.1-44.1-8.1-36 0-60 18.8-60 50.2 0 49.2 67.5 41.2 67.5 62.4 0 8.2-7.1 10.9-17 10.9-14.7 0-33.7-6.1-48.6-14.2v40c16.5 7.1 33.2 10.1 48.5 10.1 36.9 0 62.3-15.8 62.3-47.8 0-52.9-67.9-43.4-67.9-63.4zM640 261.6c0-45.5-22-81.4-64.2-81.4s-67.9 35.9-67.9 81.1c0 53.5 30.3 78.2 73.5 78.2 21.2 0 37.1-4.8 49.2-11.5v-33.4c-12.1 6.1-26 9.8-43.6 9.8-17.3 0-32.5-6.1-34.5-26.9h86.9c.2-2.3.6-11.6.6-15.9zm-87.9-16.8c0-20 12.3-28.4 23.4-28.4 10.9 0 22.5 8.4 22.5 28.4zm-112.9-64.6c-17.4 0-28.6 8.2-34.8 13.9l-2.3-11H363v204.8l44.4-9.4.1-50.2c6.4 4.7 15.9 11.2 31.4 11.2 31.8 0 60.8-23.2 60.8-79.6.1-51.6-29.3-79.7-60.5-79.7zm-10.6 122.5c-10.4 0-16.6-3.8-20.9-8.4l-.3-66c4.6-5.1 11-8.8 21.2-8.8 16.2 0 27.4 18.2 27.4 41.4.1 23.9-10.9 41.8-27.4 41.8zm-126.7 33.7h44.6V183.2h-44.6z"></path>
                        </svg>
                      </div>
                      <div class="flex-shrink-0  w-6 h-6  p-1">
                        <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="arrow-alt-right"
                             class="w-full h-full black" role="img" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 448 512">
                          <g class="fa-group">
                            <path class="fa-secondary" fill="currentColor"
                                  d="M24 192h200v128.14H24a24 24 0 0 1-24-24V216a24 24 0 0 1 24-24z"
                                  opacity="0.4"></path>
                            <path class="fa-primary" fill="currentColor"
                                  d="M265 71.11L441 239a24.08 24.08 0 0 1 0 34L265 440.94c-15.1 15.11-41 4.4-41-17V88.11c0-21.42 25.75-32.11 41-17z"></path>
                          </g>
                        </svg>
                      </div>
                      <div class="flex-shrink-0 w-10 h-10 rounded bg-purple-700 p-1">
                        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="stripe"
                             class="w-full h-full text-white"
                             role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                          <path fill="currentColor"
                                d="M165 144.7l-43.3 9.2-.2 142.4c0 26.3 19.8 43.3 46.1 43.3 14.6 0 25.3-2.7 31.2-5.9v-33.8c-5.7 2.3-33.7 10.5-33.7-15.7V221h33.7v-37.8h-33.7zm89.1 51.6l-2.7-13.1H213v153.2h44.3V233.3c10.5-13.8 28.2-11.1 33.9-9.3v-40.8c-6-2.1-26.7-6-37.1 13.1zm92.3-72.3l-44.6 9.5v36.2l44.6-9.5zM44.9 228.3c0-6.9 5.8-9.6 15.1-9.7 13.5 0 30.7 4.1 44.2 11.4v-41.8c-14.7-5.8-29.4-8.1-44.1-8.1-36 0-60 18.8-60 50.2 0 49.2 67.5 41.2 67.5 62.4 0 8.2-7.1 10.9-17 10.9-14.7 0-33.7-6.1-48.6-14.2v40c16.5 7.1 33.2 10.1 48.5 10.1 36.9 0 62.3-15.8 62.3-47.8 0-52.9-67.9-43.4-67.9-63.4zM640 261.6c0-45.5-22-81.4-64.2-81.4s-67.9 35.9-67.9 81.1c0 53.5 30.3 78.2 73.5 78.2 21.2 0 37.1-4.8 49.2-11.5v-33.4c-12.1 6.1-26 9.8-43.6 9.8-17.3 0-32.5-6.1-34.5-26.9h86.9c.2-2.3.6-11.6.6-15.9zm-87.9-16.8c0-20 12.3-28.4 23.4-28.4 10.9 0 22.5 8.4 22.5 28.4zm-112.9-64.6c-17.4 0-28.6 8.2-34.8 13.9l-2.3-11H363v204.8l44.4-9.4.1-50.2c6.4 4.7 15.9 11.2 31.4 11.2 31.8 0 60.8-23.2 60.8-79.6.1-51.6-29.3-79.7-60.5-79.7zm-10.6 122.5c-10.4 0-16.6-3.8-20.9-8.4l-.3-66c4.6-5.1 11-8.8 21.2-8.8 16.2 0 27.4 18.2 27.4 41.4.1 23.9-10.9 41.8-27.4 41.8zm-126.7 33.7h44.6V183.2h-44.6z"></path>
                        </svg>
                      </div>
                      <div class="ml-6">
                        <div class="text-sm font-medium text-gray-900">
                          {{ item.title }}
                        </div>
                        <div class="text-sm text-gray-500">
                          {{ item.description }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                  <span
                      class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    {{ item.status }}
                  </span>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <dl class="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                      <dt class="sr-only">Company</dt>
                      <dd
                          class="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                             fill="currentColor" aria-hidden="true" class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400">
                          <path fill-rule="evenodd"
                                d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                                clip-rule="evenodd"></path>
                        </svg>
                        Pending Transactions
                      </dd>
                      <dt class="sr-only">Account status</dt>
                      <dd
                          class="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                             fill="currentColor" aria-hidden="true" class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400">
                          <path fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clip-rule="evenodd"></path>
                        </svg>
                        Requires Reconciliation
                      </dd>
                    </dl>
                  </td>
                </tr>
                <!-- More rows... -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </slot>

    <slot name="no-results" v-if="!isLoading && list.length === 0">
      <h1>No results</h1>
    </slot>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Loader from "@/components/Loader";

export default {
  name: "ListUserIntegrationSettings",

  components: {Loader},

  created() {
    this.loadList()
  },

  methods: {
    loadList() {
      this.$store.dispatch('user_integration_settings/readList')
    },

    rowClicked(item) {
      this.$router.push({
        name: 'user_integration_settings_view',
        params: {
          'id': item.id
        }
      });
    },
  },

  computed: {
    ...mapGetters({
      isLoading: 'user_integration_settings/getIsLoadingList',
      list: 'user_integration_settings/getList'
    })
  }
}
</script>

<style scoped>

</style>