<template>
  <div class="">
    <modal
        :show-modal="showModal"
        title="Add New Contact"
        @closed="closed"
        @opened="focusEmail">
      <!--Footer-->
      <template #default="slotProps">

        <div class="mt-3 text-center sm:mt-0  sm:text-left">
          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
            Create Contact
          </h3>
        </div>

        <div class="">
          <div class="py-6" v-if="isLoading">
            <loader></loader>
          </div>

          <div class="" v-if="!isLoading">
            <form class="pt-6 pb-8 mb-4 bg-white rounded" @submit.prevent="createContact">
              <div class="mb-4">
                <label class="block mb-2 text-sm font-bold text-gray-700"
                       for="email"
                       :class="{'text-red-500': createErrors && createErrors.email}">
                  E-mail
                  <span class="text-red-300 mb-3">*</span>
                </label>

                <input
                    class="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                    :class="{'mb-3 border-red-500': createErrors && createErrors.email }"
                    id="email"
                    type="text"
                    placeholder="rufus@goodboyaccounting.com"
                    v-model="createForm.email"
                    required
                    autocomplete="off"
                    ref="emailField"
                />

                <p class="text-xs italic text-red-500"
                   v-if="createErrors && createErrors.email">
                  {{ createErrors.email[0] }}
                </p>
              </div>

              <div class="mb-4">
                <label class="block mb-2 text-sm font-bold text-gray-700"
                       for="first_name"
                       :class="{'text-red-500': createErrors && createErrors.first_name}">
                  First Name
                </label>

                <input
                    class="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                    :class="{'mb-3 border-red-500': createErrors && createErrors.first_name }"
                    id="first_name"
                    type="text"
                    placeholder="Rufus"
                    v-model="createForm.first_name"
                    required
                    autocomplete="off"
                />

                <p class="text-xs italic text-red-500"
                   v-if="createErrors && createErrors.first_name">
                  {{ createErrors.first_name[0] }}
                </p>
              </div>


              <div class="mb-4">
                <label class="block mb-2 text-sm font-bold text-gray-700"
                       for="first_name"
                       :class="{'text-red-500': createErrors && createErrors.last_name}">
                  Last Name
                </label>

                <input
                    class="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                    :class="{'mb-3 border-red-500': createErrors && createErrors.last_name }"
                    id="last_name"
                    type="text"
                    placeholder="Goodboy"
                    v-model="createForm.last_name"
                    required
                    autocomplete="off"
                />

                <p class="text-xs italic text-red-500"
                   v-if="createErrors && createErrors.last_name">
                  {{ createErrors.last_name[0] }}
                </p>
              </div>


            </form>

          </div>
        </div>
      </template>

      <template #buttons="slotProps">
        <div class="flex justify-end pt-2">
          <button
              class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-lg text-indigo-500  bg-transparent"
              @click.prevent="slotProps.closeModal">
            Cancel
          </button>
          <button
              class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-lg text-indigo-500  bg-transparent"
              @click.prevent="createAndEdit">
            Save & Edit
          </button>
          <button
              class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
              @click.prevent="createAndClose">
            Save & Close
          </button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Loader from "@/components/Loader";
import Modal from "@/components/Modal";

export default {
  name: "CreateUserIntegrationSettingsContact",
  components: {Modal, Loader},
  props: {
    showModal: {
      default: false,
      type: Boolean
    },
    initialData: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    createForm: {
      email: ''
    },
  }),

  mounted() {
    let self = this;

    this.$watch('showModal', function () {
      if (self.showModal) {
        self.createForm = Object.assign({}, self.createForm, self.initialData);
      }
    })
  },

  methods: {
    createObject() {
      return this.$store.dispatch('user_integration_settings_contacts/createItem', this.createForm)
          .then((createdItem) => {
            this.$emit('created', createdItem)
          })
    },

    createAndEdit() {
      return this.createObject()
        .then((createdItem) => {
          this.$emit('created', createdItem)
        }).then(() => {
            // redirect to view
        })
    },

    createAndClose() {
      return this.createObject()
          .then((createdItem) => {
            this.closed();
          })
    },

    focusEmail() {
      let self = this;

      this.$nextTick(function () {
        self.$refs.emailField.focus();
      })
    },

    closed() {
      this.$emit('closed')
    }
  },

  computed: {
    ...mapGetters({
      isLoading: 'user_integration_settings_contacts/isLoading'
    }),

    createErrors() {
      return this.$store.state.user_integration_settings_contacts.createErrors;
    }
  }
}
</script>

<style scoped>

</style>