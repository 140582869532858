<template>
  <div class="">
    <ReconciliationFilters
        @filters-changed="filtersUpdated">
    </ReconciliationFilters>

    <div v-if="isLoading">
      <div class="mt-5">
      <loader></loader>
      </div>
    </div>
    <slot v-if="!isLoading && list.data && list.data.length > 0">
      <div class="">
        <pagination @page="setPage" :meta="list.meta"></pagination>
      </div>

      <div class="pt-6 mt-3"
           v-for="item in list.data">
        <reconciliation-item
            :item="item"
            :record="record"
            :key="item.id"

            @reconciled="reconciledItem"
            :default-tax-rates="defaultTaxRates"
            :default-categories="defaultCategories"
            :default-chart-accounts="defaultChartAccounts"
            :default-bank-accounts="defaultBankAccounts">
        </reconciliation-item>
      </div>

      <div class="mt-5">
        <pagination @page="setPage" :meta="list.meta"></pagination>
      </div>
    </slot>

    <slot name="no-results" v-if="!isLoading && (!list.data || list.data.length === 0)">
      <h1>No results</h1>
    </slot>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Loader from "@/components/Loader";
import {formatMoney} from 'accounting-js';
import ReconciliationItem from "@/components/Reports/ReconciliationReport/ReconciliationItem";
import api_server, {GetListConfig} from "@/plugins/api_server";
import Pagination from "@/components/Pagination/Pagination";
import ReconciliationFilters from "@/components/Reports/ReconciliationReport/ReconciliationFilters";

const moment = require("moment-timezone");

export default {
  name: "ReconciliationReport",

  components: {
    ReconciliationFilters,
    Pagination,
    ReconciliationItem,
    Loader
  },

  props: {
    inputFilters: {
      type: Object
    },
    record: {
      type: Object
    }
  },

  data: () => ({
    searchForm: {},

    isLoadingTaxRates: false,
    defaultTaxRates: [],

    isLoadingCategories: false,
    defaultCategories: [],

    isLoadingChartAccounts: false,
    defaultChartAccounts: [],

    isLoadingBankAccounts: false,
    defaultBankAccounts: [],
  }),

  mounted() {
    this.loadList();
    this.getDefaultBankAccounts();
    this.getDefaultCategories();
    this.getDefaultChartAccounts();
    this.getDefaultTaxRates();
  },

  methods: {

    ...mapActions('user_integration_record_outputs', {
      update_list: 'reconciledItem'
    }),

    filtersUpdated(filter) {
      this.searchForm = {filter};
      this.loadList();
    },

    reconciledItem(item) {
      return this.update_list(item);
    },

    testRemoveItem() {
      this.$store.dispatch(
          "user_integration_record_outputs/reconciledItem",
          this.list.data[0]
      )
    },

    getDefaultTaxRates() {
      this.isLoadingTaxRates = true;

      return api_server.getClient()
          .then((client) => {
            return client.ListTaxRates({
              filter: {
                user_integration_settings_id: this.record.id
              }
            }, null, GetListConfig())
                .then((response) => {
                  this.defaultTaxRates = response.data.data;
                  return response.data.data;
                })
                .catch((error) => {
                  throw error;
                })
                .finally(() => {
                  this.isLoadingTaxRates = false;
                });
          })
    },

    getDefaultCategories() {
      this.isLoadingCategories = true;

      return api_server.getClient()
          .then((client) => {
            return client.ListTrackingCategories({
              filter: {
                user_integration_settings_id: this.record.id
              }
            }, null, GetListConfig())
                .then((response) => {
                  this.defaultCategories = response.data.data;
                  return response.data.data;
                })
                .catch((error) => {
                  throw error;
                })
                .finally(() => {
                  this.isLoadingCategories = false;
                });
          })
    },

    getDefaultChartAccounts() {
      this.isLoadingChartAccounts = true;

      return api_server.getClient()
          .then((client) => {
            return client.ListIntegrationAccounts({
              filter: {
                user_integration_settings_id: this.record.id
              }
            }, null, GetListConfig())
                .then((response) => {
                  this.defaultChartAccounts = response.data.data;
                  return response.data.data;
                })
                .catch((error) => {
                  throw error;
                })
                .finally(() => {
                  this.isLoadingChartAccounts = false;
                });
          })
    },

    getDefaultBankAccounts() {
      this.isLoadingBankAccounts = true;

      return api_server.getClient()
          .then((client) => {
            return client.ListIntegrationAccounts({
              filter: {
                user_integration_settings_id: this.record.id,
                search: this.searchChartAccounts,
                account_type: 'BANK'
              }
            }, null, GetListConfig())
                .then((response) => {
                  this.defaultBankAccounts = response.data.data;
                  return response.data.data;
                })
                .catch((error) => {
                  throw error;
                })
                .finally(() => {
                  this.isLoadingBankAccounts = false;
                });
          })
    },

    asLocalTime(datetime) {
      let parsedTime = null;

      if (this.record.input_timezone) {
        parsedTime = moment(datetime).tz(this.record.input_timezone)
      } else {
        parsedTime = moment(datetime)
      }

      return parsedTime
          .format('MMM Do, YYYY hh:mm:ss A')
    },

    asMoney(money, currency) {
      return formatMoney(money, {symbol: currency, format: "%v"});
    },

    loadList(params = {}) {
      let filterParams = Object.assign(
          {},
          this.inputFilters,
          this.searchForm,
          params
      );

      this.$store.dispatch(
          'user_integration_record_outputs/readNeedsReconcile',
          filterParams,
      )
    },

    setPage(page) {
      return this.loadList({
        page: {
          number: page
        }
      });
    },

    rowClicked(item) {
    },
  },

  computed: {
    isLoading() {
      return this.isLoadingReport ||
          this.isLoadingTaxRates ||
          this.isLoadingBankAccounts ||
          this.isLoadingCategories ||
          this.isLoadingChartAccounts
    },

    ...mapGetters({
      isLoadingReport: 'user_integration_record_outputs/getIsLoadingNeedsReconcile',
      list: 'user_integration_record_outputs/getNeedsReconcile'
    })
  }
}
</script>

<style scoped>

</style>