import { render, staticRenderFns } from "./OutputFeed.vue?vue&type=template&id=45e86ac2&scoped=true"
import script from "./OutputFeed.vue?vue&type=script&lang=js"
export * from "./OutputFeed.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45e86ac2",
  null
  
)

export default component.exports