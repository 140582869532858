<template>
  <layout-user-integration-settings
      active-navigation="settings">
    <div class="max-w-7xl mx-auto pb-10 lg:py-12 lg:px-8">
      <div class="lg:grid lg:grid-cols-12 lg:gap-x-5">
        <aside class="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
          <nav class="space-y-1">
            <a href="#"
               class="bg-gray-50 text-orange-600 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium"
               aria-current="page">
              <!-- Heroicon name: outline/view-grid-add -->
              <svg class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                   xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                   aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"/>
              </svg>
              <span class="truncate">
            Integrations
          </span>
            </a>
          </nav>
        </aside>

        <!-- Payment details -->
        <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
          <section aria-labelledby="payment_details_heading">
            <form action="#" method="POST">
              <div class="shadow sm:rounded-md sm:overflow-hidden">
                <div class="bg-white py-6 px-4 sm:p-6">
                  <div>
                    <h2 id="payment_details_heading" class="text-lg leading-6 font-medium text-gray-900">Integration
                      details</h2>
                    <p class="mt-1 text-sm text-gray-500">Update details for this integration.</p>
                  </div>

                  <div class="mt-6 grid grid-cols-4 gap-6">
                    <div class="col-span-4">
                      <label for="first_name" class="block text-sm font-medium text-gray-700">Integration Name</label>
                      <input
                          type="text"
                          name="first_name"
                          id="first_name"
                          autocomplete="cc-given-name"
                          class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm">
                    </div>

                  </div>


                  <form-toggle
                      text="Reconcile Transactions"
                      name="reconcile_transactions"
                      :value="settings_form"
                      @input="updateForm">
                  </form-toggle>


                  <div class="mt-6 grid grid-cols-4 gap-6">
                    <div class="py-4 sm:py-5 col-span-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                      <dt class="text-sm font-medium text-gray-500" id="timezone-option-label">
                        Reconcile Transactions
                      </dt>
                      <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <!-- Enabled: "bg-purple-600", Not Enabled: "bg-gray-200" -->
                        <button type="button"
                                class="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-auto"
                                aria-pressed="true" aria-labelledby="timezone-option-label">
                          <span class="sr-only">Use setting</span>
                          <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                          <span aria-hidden="true"
                                class="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
                        </button>
                      </dd>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>

          <section aria-labelledby="source_data">
            <form action="#" method="POST">
              <div class="shadow sm:rounded-md sm:overflow-hidden">
                <div class="bg-white py-6 px-4 sm:p-6">
                  <div>
                    <h2 id="source_data" class="text-lg leading-6 font-medium text-gray-900">Source Information</h2>
                    <p class="mt-1 text-sm text-gray-500">Update information regarding the source of this
                      integration</p>
                  </div>

                  <div class="mt-6 grid grid-cols-4 gap-6">

                    <div class="col-span-4">
                      <label for="last_name" class="block text-sm font-medium text-gray-700">Data Source</label>
                      <p id="last_name"
                         class="mt-1 block w-full rounded-md py-2 px-3 sm:text-sm">
                        Stripe
                      </p>
                    </div>

                    <div class="col-span-4">
                      <label for="first_name" class="block text-sm font-medium text-gray-700">Source Timezone</label>
                      <select id="country" name="country" autocomplete="country"
                              class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm">
                        <option>Australia/Sydney</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>

          <section aria-labelledby="source_data">
            <form action="#" method="POST">
              <div class="shadow sm:rounded-md sm:overflow-hidden">
                <div class="bg-white py-6 px-4 sm:p-6">
                  <div>
                    <h2 id="source_data" class="text-lg leading-6 font-medium text-gray-900">Output Information</h2>
                    <p class="mt-1 text-sm text-gray-500">Update information regarding the destination of this
                      integration</p>
                  </div>

                  <div class="mt-6 grid grid-cols-4 gap-6">

                    <div class="col-span-4">
                      <label for="last_name" class="block text-sm font-medium text-gray-700">Data Output</label>
                      <p id="last_name"
                         class="mt-1 block w-full rounded-md py-2 px-3 sm:text-sm">
                        Xero
                      </p>
                    </div>

                    <div class="col-span-4">
                      <label for="first_name" class="block text-sm font-medium text-gray-700">Output Timezone</label>
                      <select id="country" name="country" autocomplete="country"
                              class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm">
                        <option>Australia/Sydney</option>
                        <option>Canada</option>
                        <option>Mexico</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>


          <section aria-labelledby="source_data">
            <form action="#" method="POST">
              <div class="shadow sm:rounded-md sm:overflow-hidden">
                <div class="bg-white py-6 px-4 sm:p-6">
                  <div>
                    <h2 id="source_data" class="text-lg leading-6 font-medium text-gray-900">Aggregate Information</h2>
                    <p class="mt-1 text-sm text-gray-500">Aggregates will send a single, daily transaction total through
                      to the destination depending on type</p>
                  </div>

                  <form-toggle
                      text="Aggregate Fees"
                      name="aggregate_fees"
                      :value="settings_form"
                      @input="updateForm">
                  </form-toggle>

                  <form-toggle
                      text="Aggregate Charges"
                      name="aggregate_charges"
                      :value="settings_form"
                      @input="updateForm">
                  </form-toggle>

                  <form-toggle
                      text="Aggregate Adjustments"
                      name="aggregate_adjustments"
                      :value="settings_form"
                      @input="updateForm">
                  </form-toggle>

                  <form-toggle
                      text="Aggregate Refunds"
                      name="aggregate_refunds"
                      :value="settings_form"
                      @input="updateForm">
                  </form-toggle>

                  <form-toggle
                      text="Aggregate Refunds"
                      name="aggregate_transfers"
                      :value="settings_form"
                      @input="updateForm">
                  </form-toggle>

                </div>
              </div>
            </form>
          </section>
        </div>
      </div>
    </div>

  </layout-user-integration-settings>
</template>

<script>
import {mapGetters} from "vuex";
import LayoutUserIntegrationSettings from "@/views/UserIntegrationSettings/Layout";
import FormToggle from "@/components/Forms/FormToggle";

export default {
  name: "ViewAccountReports",

  components: {
    LayoutUserIntegrationSettings,
    FormToggle
  },

  data: () => ({
    settings_form: {
      aggregate_fees: false
    }
  }),

  methods: {
    updateForm(val) {
      this.settings_form = Object.assign({}, this.settings_form, val);
    }
  },

  computed: {
    ...mapGetters({
      isLoading: 'user_integration_settings/isLoading',
      record: 'user_integration_settings/getView'
    })
  }
}
</script>

<style scoped>

</style>