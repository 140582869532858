<template>
  <div class="bg-white mt-5 border-gray-200  lg:min-w-0 lg:flex-1 lg:flex lg:items-center lg:justify-between p-2">
    <div class="min-w-0 flex-1">
      <filter-text
          name="search"
          text="Search contacts"
          @input="updateTextSearchField"
          :value="searchForm">
      </filter-text>
    </div>
  </div>
</template>

<script>
import FilterText from "@/components/Filters/FilterText";
export default {
  name: "ContactFilters",

  components: {
    FilterText
  },
  
  data: () => ({
    searchForm: {},
  }),

  methods: {
    updateSearch(value) {
      this.searchForm = Object.assign({}, this.searchForm, value)
      this.emitFilterUpdate();
    },


    emitFilterUpdate() {
      const form = this.searchForm;

      const cleanedFilters = Object.keys(form)
          .reduce((carry, item) => {
            if (this.searchForm[item]) {
              carry[item] = form[item];
            }

            return carry
          }, {});


      this.$emit('filters-changed', cleanedFilters);
    },

    updateTextSearchField(value) {
      this.updateSearch(value)
    },

    updateCountrySearch(value) {
      this.updateSearch(value)
    },
  }
}
</script>

<style scoped>

</style>