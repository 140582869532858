<template>
    <div class="">
        <modal
                :show-modal="showModal"
                title="Add New Managed Account"
                @closed="closed"
                @opened="focusTitle">
            <!--Footer-->
            <template #default="slotProps">
                <div class="">
                    <div class="py-6" v-if="isLoading">
                        <loader></loader>
                    </div>

                    <div class="" v-if="!isLoading">
                        <form class="pt-6 pb-8 mb-4 bg-white rounded" @submit.prevent="createAccount">
                            <div class="mb-4">
                                <label class="block mb-2 text-sm font-bold text-gray-700"
                                       for="title"
                                       :class="{'text-red-500': createErrors && createErrors.title}">
                                    Account Name
                                    <span class="text-red-300 mb-3">*</span>
                                </label>
                                <input
                                        class="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                        :class="{'mb-3 border-red-500': createErrors && createErrors.title }"
                                        id="title"
                                        type="text"
                                        placeholder="Good Boy Accounting, LTD"
                                        v-model="createForm.title"
                                        required
                                        autocomplete="title"
                                        ref="titleField"
                                />

                                <p class="text-xs italic text-red-500"
                                   v-if="createErrors && createErrors.title">
                                    {{ createErrors.title[0] }}
                                </p>
                            </div>
                        </form>


                        <div class="flex justify-end pt-2">
                            <button class="px-4 bg-transparent p-3 rounded-lg text-indigo-500 hover:bg-gray-100 hover:text-indigo-400 mr-2"
                                    @click.prevent="slotProps.closeModal">
                                Cancel
                            </button>
                            <button class="modal-close px-4 bg-indigo-500 p-3 rounded-lg text-white hover:bg-indigo-400"
                                    @click.prevent="createAccount">
                                Add Account
                            </button>
                        </div>
                    </div>
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import Loader from "@/components/Loader";
    import Modal from "@/components/Modal";

    export default {
        name: "CreateUserIntegrationSettings",
        components: {Modal, Loader},
        props: {
            showModal: {
                default: false,
                type: Boolean
            }
        },
        data: () => ({
            createForm: {
                title: ''
            }
        }),
  
        methods: {
            createAccount() {
                this.$store.dispatch('user_integration_settings/createItem', this.createForm)
                    .then((created_object) => {
                        this.$emit('created', created_object)
                    })
            },

            focusTitle() {
                this.$refs.titleField.focus();
            },

            closed() {
                this.$emit('closed')
            }
        },

        computed: {
            ...mapGetters({
                isLoading: 'user_integration_settings/isLoading'
            }),

            createErrors() {
                return this.$store.state.user_integration_settings.createErrors;
            }
        }
    }
</script>

<style scoped>

</style>